@value colors: 'styles/colors.module.css';
@value BORDER_GREY from colors;

.container {
}

.header {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.faqs {
}

.faq {
  border-bottom: solid 1px BORDER_GREY;
}

.faq:first-child {
  border-top: solid 1px BORDER_GREY;
}
