@value breakpoints: 'styles/breakpoints.module.css';
@value TABLET_WIDTH, MOBILE_WIDTH from breakpoints;
@value gradients: 'components/dashboard/clients/coach-home/gradients.module.css';
@value greenGradient, lightRedGradient from gradients;

.container {
  position: relative;
  background: #F5F8FB;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3.75rem;
  padding: 3.75rem 1.875rem;
}

.content {
  max-width: 50rem;
}

.header {
  font-size: 1.375rem;
  font-weight: 800;
  text-align: center;
  margin-bottom: 3.5rem;
}

.grid {
  display: grid;
  grid-template-columns: 3fr 2fr 2fr;
  column-gap: 0.75rem;
  color: #030017;
}

.gridHeader {
  height: 7rem;
  background: #FFF;
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 700;
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
  padding: 0.75rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.gridHeaderPlaceholder { 
  height: 7rem;
}

.rowLabel {
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: -0.015rem;
  height: 3.375rem;
  display: flex;
  align-items: center;
}

.gridItem {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F5F8FB;
  padding: 1rem 0.7rem;
  height: 3.375rem;
}

.last {
  border-bottom-right-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}

.column {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.06);
  max-width: 15rem;
  border-radius: 0.625rem;
}

.kickoffIcon {
  width: 2rem;
}

.logoWords {
  width: 4rem;
}

.priceCard {
  position: relative;
  margin-top: 1rem;
  padding: 0.875rem 0.5rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.625rem;
  max-width: 15rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.06);
}

.competitorCard {
  border-color: #FF6969;
}

.kickoffCard {
  border-color: #4DD182;
}

.priceText {
  font-size: 1.375rem;
  font-weight: 800;
}

.competitorPriceText {
  composes: lightRedGradient;
}

.kickoffPriceText {
  composes: greenGradient;
}

.durationText {
  font-size: 0.875rem;
  font-weight: 700;
}

.competitorDurationText {
  composes: lightRedGradient;
}

.kickoffDurationText {
  composes: greenGradient;
}

.emojis {
  font-size: 1.375rem;
}

.floatingImg {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 3.125rem;
  width: 3.125rem;
  border-radius: 100%;
}

@media screen and (min-width: MOBILE_WIDTH) {
  .logoWords {
    width: 6rem;
  }
}

@media screen and (min-width: TABLET_WIDTH) {

  .header {
    font-size: 1.875rem;
  }

  .rowLabel {
    font-size: 1.125rem;
  }


  .gridHeader {
    height: 4.75rem;
    flex-direction: row-reverse;
  }

  .gridHeaderPlaceholder { 
    height: 4.75rem;
  }

  .priceCard {
    padding: 1.5rem;
  }

  .emojis {
    font-size: 1.5rem;
    position: absolute;
    top: -1rem;
    right: -1rem;
  }

  .priceText {
    font-size: 1.875rem;
    font-weight: 800;
  }

  .floatingImg {
    height: 5rem;
    width: 5rem;
  }
}