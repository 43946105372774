@value colors: 'styles/colors.module.css';
@value PURPLE, WHITE, WARNING_500, NEUTRAL_GRAY_100, GRADIENT_PRIMARY_DEFAULT from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;
/* .container {
  display: flex;
} */

.container {
  padding-bottom: 1.5rem;
}

.container::before {
  content: ' ';
  display: block;
  height: 42px;
  margin-top: -42px;
  visibility: hidden;
}

.title {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
  margin: 1.5rem 1rem;
}

.buttonContainer {
  display: none;
}

.reviewsContainer {
  display: flex;
  height: 400px;
  width: 100%;
}

.reviewsContainer :global(.swiper) {
  padding-left: 1rem;
}

.reviewsContainer :global(.swiper-slide) {
  width: 85%;
}

.reviewsContainer :global(.swiper-pagination-bullet-active) {
  background: linear-gradient(80.11deg, #830051 -12.82%, #ff6d42 109.97%);
}

.reviewCard {
  display: flex;
  flex-direction: column;
  height: 320px;
  padding: 1.5rem 1rem;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.11);
  border-radius: 4px;
}

.reviewCard:hover {
  box-shadow: 0px 13px 37px rgba(0, 0, 0, 0.21);
}

.stars {
  display: flex;
  margin-bottom: 1rem;
}

.star {
  color: WARNING_500;
}

.text {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #333333;
}

.text p {
  margin-bottom: 1rem;
}

.text p strong {
  font-weight: 500;
}

.reviewer {
  display: flex;
  flex: 1;
  align-items: flex-end;
}

.headshot {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 1rem;
  background-color: NEUTRAL_GRAY_100;
}

.reviewerName {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 500;
  color: #333333;
}

.reviewerSource {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #333333;
}

.reviewsContainer :global(.swiper-button-prev) {
  top: inherit;
  bottom: 1rem;
  left: 40%;
  background: GRADIENT_PRIMARY_DEFAULT;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.reviewsContainer :global(.swiper-button-prev)::after {
  font-size: 1rem;
}

.reviewsContainer :global(.swiper-button-next) {
  top: inherit;
  bottom: 1rem;
  right: 40%;
  background: GRADIENT_PRIMARY_DEFAULT;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.reviewsContainer :global(.swiper-button-next)::after {
  font-size: 1rem;
}

@media screen and (min-width: TABLET_WIDTH) {
  .titleContainer {
    width: 60%;
  }

  .title {
    font-size: 3rem;
    line-height: 4rem;
  }

  .reviewsContainer :global(.swiper-slide) {
    width: 50%;
  }

  .reviewsContainer :global(.swiper-button-prev) {
    left: 45%;
  }

  .reviewsContainer :global(.swiper-button-next) {
    right: 45%;
  }

  .reviewsContainer :global(.swiper-pagination-bullet) {
    width: 16px;
    height: 16px;
  }
}

@media screen and (min-width: DESKTOP_MED_WIDTH) {
  .container {
    display: flex;
    align-items: flex-start;
    margin: 6.75rem;
    overflow: hidden;
    margin-right: 0;
  }

  .container::before {
    content: ' ';
    display: block;
    height: 76px;
    margin-top: -76px;
    visibility: hidden;
  }

  .titleContainer {
    width: 40%;
  }

  .title {
    font-size: 3rem;
    line-height: 4rem;
    margin: 0;
    margin-bottom: 1rem;
  }

  .reviewsContainer {
    width: 60%;
    overflow: hidden;
  }

  .reviewsContainer :global(.swiper-slide) {
    width: 40%;
  }

  .buttonContainer {
    display: block;
  }
}
