@value colors: 'styles/colors.module.css';
@value WHITE from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.container {
  background-color: WHITE;
  padding: 4rem 2rem;
}

.content {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
}

.header {
  color: black;
  font-size: 2rem;
  line-height: 2.25rem;
  text-align: center;
  font-weight: bold;
  margin-bottom: 1rem;
}

.description {
  font-size: 1.375rem;
  line-height: 2rem;
  text-align: center;
  margin: auto;
  max-width: 960px;
}

.imageContainer {
  margin: 1rem auto 2rem auto;
  max-width: 960px;
}

.bullet {
  list-style-type: disc;
}

.list {
  text-align: start;
  margin-left: 2rem;
}

@media screen and (min-width: TABLET_WIDTH) {
  .imageWrapper {
    object-fit: contain;
    height: 325px;
  }
  /* 
  .image {
    width: 75%;
  } */
}

@media screen and (min-width: DESKTOP_WIDTH) {
  .items {
    flex-direction: row;
    justify-content: space-evenly;
  }

  .item {
    width: 33%;
    margin: 0 1rem;
  }

  .imageWrapper {
    height: 200px;
  }

  .image {
    width: 100%;
  }
}
