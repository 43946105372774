@value colors: 'styles/colors.module.css';
@value PURPLE, WHITE from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.planSelectionContainer {
  display: flex;
  align-items: stretch;
  flex: 1;
  width: 100%;
}

.plans {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 75rem;
  justify-content: stretch;
  align-items: stretch;
}

.fullWidth {
  width: 100%;
}

.planWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
}

.programCardWrapper {
  display: flex;
  align-items: stretch;
  flex: 1;
  padding: 0 1.5rem;
}

@media screen and (min-width: TABLET_WIDTH) {
  .programCardWrapper {
    padding: 0;
  }
}

@media screen and (min-width: DESKTOP_MED_WIDTH) {
  .plans {
    flex-direction: row;
    align-items: flex-start;
  }

  .planWrapper {
    width: 100%;
  }
}