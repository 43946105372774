@value colors: 'styles/colors.module.css';
@value NEUTRAL_GRAY_100, NEUTRAL_GRAY_300, NEUTRAL_GRAY_900, SUCCESS_200, SILVER, PURPLE, WHITE, SUB_TEXT_GREY, BLACK from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value TABLET_WIDTH from breakpoints;

.container {
  display: flex;
  padding: 1rem 0;
  position: relative;
  flex-direction: column;
  margin-bottom: 0.5rem;
}

.header {
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 2rem;
}

.certifications {
  color: SUB_TEXT_GREY;
}

.certification {
  background-color: NEUTRAL_GRAY_100;
  border: 1px solid NEUTRAL_GRAY_300;
  border-radius: 0.25rem;
  color: NEUTRAL_GRAY_900;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: 0.07rem;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  min-height: 1.5rem;
  padding: 0 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
}

.certificationCount {
  cursor: pointer;
  font-size: 1rem;
  margin-top: 0.25rem;
  text-decoration: underline;
}

.bio.certifications {
  margin-top: 1rem;
  padding: 0 1.5rem;
  color: BLACK;
}

.bio.certifications .certification {
  list-style-type: disc;
}

.certification:first-child {
  padding-top: 0;
}

.certification:last-child {
  padding-bottom: 0;
}
