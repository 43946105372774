@value colors: 'styles/colors.module.css';
@value LIGHT_TEXT_GREY, SHADES_BLACK, WHITE, NEUTRAL_GRAY_50 from colors;

.container {
  background-color: WHITE;
  max-width: 37.5rem;
  width: 100%;
}

.question {
  border: none;
  background-color: transparent;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  user-select: none;
  padding: 0.8rem 1rem;
  color: SHADES_BLACK;
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.question > span {
  text-align: left;
}

.open {
  background-color: NEUTRAL_GRAY_50;
  border-bottom: solid 1px #cbd5e1;
}

.answer {
  font-size: 1.125rem;
  padding: 0.8rem 1rem;
  line-height: 1.75rem;
  letter-spacing: -0.02em;
  background-color: WHITE;
}
