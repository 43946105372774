.chip {
  margin-left: 1rem;
  font-weight: normal;
  text-transform: none;
  letter-spacing: normal;
}

.imageOverlay {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}
