@value colors: 'styles/colors.module.css';
@value LIGHT_TEXT_GREY from colors;

.container {
}

.question {
  composes: unstyled from 'styles/buttons.module.css';
  padding: 0.75rem 0;
  color: LIGHT_TEXT_GREY;
  text-align: left;
}

.answer {
  padding: 0 0 0.75rem 1rem;
}
