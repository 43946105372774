@value colors: 'styles/colors.module.css';
@value PURPLE from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, MOBILE_WIDTH_MAX, TABLET_WIDTH from breakpoints;

.section {
  composes: section from '../styles.module.css';
  /* margin-bottom: 0 !important; */
}

.sectionMain {
  margin-top: 1rem;
}

.images {
  composes: sectionImages from '../styles.module.css';
}

.perfectCoachImage {
  composes: sectionImage from '../styles.module.css';
  height: 100%;
}

.link {
  color: PURPLE;
  text-decoration: underline;
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .section {
    margin-bottom: -1rem !important;
  }
}
