@value colors: 'styles/colors.module.css';
@value MID_GREY from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value MOBILE_WIDTH_MAX from breakpoints;

.trainersSection {
  display: flex;
  background-color: MID_GREY;
}

.trainersSectionMobile {
  display: none;
}

.main {
  width: 60%;
  padding: 4.5rem 3.125rem;
}

.gallery {
  width: 40%;
}

.galleryItems {
  display: flex;
  flex-wrap: wrap;
  margin: -0.75rem;
  padding-bottom: 3rem;
}

.header {
  composes: header from 'styles/homepage.module.css';
  line-height: 3.9rem;
  margin-bottom: 2.5rem;
}

.trainerName {
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
  font-weight: 500;
}

.trainerBio {
  font-size: 1.125rem;
  max-width: 26.5rem;
}

.imageContainer {
  position: relative;
  width: 50%;
  height: 16vw;
  padding: 0.75rem;
}

.imageWrapper {
  width: 100%;
  height: 100%;
}

.image {
  filter: grayscale(100%);
  width: 100%;
  height: 100%;
  object-position: center;
  transition: filter 0.2s linear;
}

.imageContainerActive .image {
  filter: none;
}

.parenthesis {
  width: 2.4375rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.2s linear;
  z-index: 1;
}

.imageContainerActive .parenthesis {
  opacity: 1;
}

.parenthesisLeft {
  composes: parenthesis;
  left: 2.75rem;
}

.parenthesisRight {
  composes: parenthesis;
  right: 2.75rem;
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .trainersSectionMobile {
    display: block;
    padding: 1.5rem;
    background-color: MID_GREY;
  }

  .trainersSection {
    display: none;
  }

  .header {
    margin-bottom: 1.5rem;
  }
}

@media screen and (max-width: TABLET_WIDTH) {
  .primaryImageWrapper {
    width: 50% !important;
  }
}
