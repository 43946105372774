@value colors: 'styles/colors.module.css';
@value BACKGROUND_GREY, LIGHTER_GREY, WHITE, CHARCOAL, PURPLE from colors;

.billingPeriods {
  display: flex;
  background-color: BACKGROUND_GREY;
  border-radius: 1.25rem;
  margin-bottom: 2.2rem;
}

.billingPeriod {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6.5rem;
  height: 2.5rem;
  color: LIGHTER_GREY;
  font-weight: 500;
  position: relative;
  border-radius: 0.3rem;
}

:global(.--has-hover) .billingPeriod:not(.billingPeriodActive):hover {
  cursor: pointer;
}

.billingPeriodFirst {
  border-radius: 1.25rem 0.3rem 0.3rem 1.25rem;
}

.billingPeriodLast {
  border-radius: 0.3rem 1.25rem 1.25rem 0.3rem;
}

.billingPeriodActive {
  background-color: WHITE;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.1);
  color: CHARCOAL;
}

.discount {
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  font-size: 0.9rem;
  width: 100%;
  text-align: center;
  padding: 0.4rem 0;
  color: PURPLE;
}
