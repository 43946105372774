@value colors: 'styles/colors.module.css';
@value WHITE, ORANGE, PURPLE, BORDER_GREY from colors;

.blogPostLink {
  composes: unstyled from 'styles/links.module.css';
  display: block;
}

.blogPost {
  position: relative;
  background-color: WHITE;
  border-bottom: solid 2px;
  border-color: BORDER_GREY;
  transition: border-color 0.2s;
}

:global(.--has-hover) .blogPost:hover {
  border-color: ORANGE;
}

.blogPostDate {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  background-color: PURPLE;
  color: WHITE;
  font-size: 0.7em;
  font-weight: 300;
  padding: 0.3em 0.6em;
  opacity: 0.9;
}

.blogPostHeroWrapper {
  height: 12em;
  overflow: hidden;
}

.blogPostHero {
  height: 100%;
  z-index: 1;
  transform: scale(1);
  transition: all 250ms cubic-bezier(0.57, 0.425, 0.445, 0.995) !important;
}

.blogPostHero img {
}

:global(.--has-hover) .blogPost:hover .blogPostHero {
  transform: scale(1.05);
}

.blogPostInfo {
  padding: 1em;
  min-height: 11.5em;
}

.limitLines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.blogPostTitle {
  composes: limitLines;
  font-weight: 500;
  margin-bottom: 0.5em;
  font-size: 1.1em;
}

.blogPostExcerpt {
  composes: limitLines;
  font-size: 0.9em;
}
