@value breakpoints: 'styles/breakpoints.module.css';
@value TABLET_WIDTH from breakpoints;
@value gradients: 'components/dashboard/clients/coach-home/gradients.module.css';
@value redGradient from gradients;
@value colors: 'styles/colors.module.css';
@value BLUE_GRAYS_00 from colors;

.container {
  padding: 5rem 2rem;
  background-color: BLUE_GRAYS_00;
  display: flex;
  justify-content: center;
}

.content {
  width: 100%;
  max-width: 60rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 1.5rem;
  margin: 0 1rem;
}

.header {
  font-size: 1.625rem;
  font-weight: 800;
  line-height: 2.125rem;
  max-width: 60rem;
  padding: 0 1rem;
}

.text {
  font-size: 0.875rem;
  font-weight: 600;
  max-width: 60rem;
  max-width: 90vw;
}

.trainers {
  max-width: calc(100dvw);
  min-width: 0;
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-bottom: 2rem;
}

.trainers::-webkit-scrollbar {
  display: none;
}

.trainerCard {
  margin-top: 5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  background: #FFF;
  padding: 6rem 2rem 1.5rem;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
  border-radius: 1.25rem;
  gap: 1rem;
  width: 15rem;
  flex-shrink: 0;
}

.trainerCard:first-child {
  margin-left: 2rem;
}

.trainerCard:last-child {
  margin-right: 2rem;
}

.trainerName {
  font-size: 1.25rem;
  font-weight: 800;
  flex: 1;
}

.trainerCerts {
  font-size: 1rem;
  font-weight: 600;
  flex: 1;
}

.trainerSpecialty {
  composes: redGradient;
  font-size: 0.875rem;
  font-weight: 800;
  flex: 1;
}

.certImage {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #FFF;
  width: 2.5rem;
  height: 2.5rem;
  top: -2.5rem;
  right: 3rem;
}

.certImg {
  height: 32px;
  width: 32px;
  object-fit: contain;
  object-position: center;
}

.trainerImage {
  border-radius: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -35%);
  border: 8px solid #FFF;
  height: 8.75rem;
  width: 8.75rem;
}

@media screen and (min-width: TABLET_WIDTH) {
  .header {
    font-size: 2.5rem;
  }
  
  .text {
    font-size: 1.125rem;
  }

  .trainers {
    justify-content: center;
    align-items: stretch;
  }

  .trainerCard {
    width: unset;
    flex: 1;
  }

  .certImage {
    right: 18%;

  }
}