@value breakpoints: 'styles/breakpoints.module.css';
@value TABLET_WIDTH from breakpoints;


.container {
  background: #F5F8FB;
  padding: 3.75rem 1.875rem;
}

.content {
  margin: 0 auto;
  max-width: 50rem;
  margin-top: 3rem;
  gap: 1.25rem;  
  flex-direction: column;
  display: flex;
}

.header {
  font-size: 1.375rem;
  font-weight: 800;
  text-align: center;
}

.numberAndIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
}


.number {
  font-size: 1.75rem;
  font-weight: 800;
}

.icon {
  border-radius: 100%;
  height: 2.75rem;
  width: 2.75rem;
  transform: translateX(1rem)
}

.card {
  background: #FFF;
  padding: 0.75rem;
  font-size: 0.875rem;
  font-weight: 700;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.06);
  border-radius: 0.75rem;
}

.cardSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  gap: 0.5rem;
}

.cardSection:nth-child(even) {
  flex-direction: row-reverse;
}

.cardSection:nth-child(even) .numberAndIcon {
  flex-direction: row-reverse;
}

.cardSection:nth-child(even) .icon {
  transform: translateX(-1rem)
}

@media (min-width: TABLET_WIDTH) {
  .container {
    padding: 5rem 1.875rem;
  }
  
  .content {
    flex-direction: row;
    margin-top: 4rem;
  }

  .numberAndIcon {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    gap: 1rem;
  }

  .cardSection {
    flex-direction: column;
    position: relative;
    flex: 1;
  }
  
  .card {
    font-size: 1.25rem;
    padding: 2.75rem 1.25rem;
  }

  .cardSection:nth-child(even) {
    flex-direction: unset;
  }
  
  .cardSection:nth-child(even) .numberAndIcon {
    flex-direction: unset;
  }
  
  .icon {
    height: 3.75rem;
    width: 3.75rem;
    transform: unset;
  }
  .cardSection:nth-child(even) .icon {
    transform: unset;
  }
}
