@value breakpoints: 'styles/breakpoints.module.css';
@value TABLET_WIDTH from breakpoints;

.col {
  width: 100%;
  margin-bottom: 1.5rem;
}

@media screen and (min-width: TABLET_WIDTH) {
  .col:not(.imageContainer) {
    width: 60%;
  }

  .col.imageContainer {
    width: 40%;
  }

  .col:last-child {
    padding-left: 2rem;
  }
}
