@value colors: 'styles/colors.module.css';
@value PURPLE, WARNING_500, GRADIENT_PRIMARY_DEFAULT from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value MOBILE_WIDTH, NEW_TABLET_PORTRAIT, NEW_TABLET_PORTRAIT_MAX from breakpoints;

.container {
  width: 100%;
  padding-top: 1rem;
  height: auto;
}

.container::before {
  content: ' ';
  display: block;
  height: 42px;
  margin-top: -42px;
  visibility: hidden;
}

.title {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
  margin: 1.5rem 1rem;
}

.testimonialContainer {
  display: flex;
  height: auto;
  min-height: 40rem;
  width: 100%;
}

.testimonialContainer :global(.swiper) {
  padding-left: 1rem;
}

.testimonialContainer :global(.swiper-slide) {
  width: 94%;
}

.testimonialContainer :global(.swiper-pagination-bullet-active) {
  background: linear-gradient(80.11deg, #830051 -12.82%, #ff6d42 109.97%);
}

.ctaContainer {
  display: block;
  width: 100%;
  padding-top: 1rem;
}

.cta {
  width: 100%;
}

.testimonialCard {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 38rem;
  padding: 1.5rem 1.5rem;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.11);
  border-radius: 4px;
}

.testimonialCardExpanded {
  height: fit-content;
}

.chevronDown {
  margin-left: 0.25rem;
}

.readMoreText {
  padding-top: 0.25rem;
  display: flex;
  align-items: center;
  color: PURPLE;
}

.stars {
  display: flex;
  margin-bottom: 1rem;
}

.star {
  color: WARNING_500;
}

.text {
  padding: 0.75rem 0;
  line-height: 1.5rem;
  color: #333333;
}

.text p {
  margin-bottom: 1rem;
}

.text p strong {
  font-weight: 500;
}

.pictures {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.headshot {
  width: 100%;
  height: 20rem;
}

.testimonialName {
  padding-top: 0.75rem;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 500;
  color: #333333;
}

.testimonialContainer :global(.swiper-button-prev) {
  top: 40%;
  background: GRADIENT_PRIMARY_DEFAULT;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.testimonialContainer :global(.swiper-button-prev)::after {
  font-size: 1rem;
}

.testimonialContainer :global(.swiper-button-next) {
  top: 40%;
  background: GRADIENT_PRIMARY_DEFAULT;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.testimonialContainer :global(.swiper-button-next)::after {
  font-size: 1rem;
}

.testimonialContainer :global(.swiper-autoheight) {
  min-height: fit-content;
  height: 100%;
  padding-bottom: 3rem;
}

@media screen and (min-width: MOBILE_WIDTH) {
  .testimonialCard {
    padding-left: 3rem;
    padding-right: 3rem;
    min-height: 40rem;
  }

  .testimonialContainer {
    min-height: 44rem;
  }
}

@media screen and (min-width: NEW_TABLET_PORTRAIT) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    margin: 1rem 0 0 0;
  }

  .container::before {
    content: ' ';
    display: block;
    height: 76px;
    margin-top: -76px;
    visibility: hidden;
  }

  .title {
    font-size: 3rem;
    line-height: 4rem;
    margin: 1.5rem 1rem;
  }

  .testimonialContainer {
    width: 100%;
    min-height: 44rem;
  }

  .testimonialCard {
    padding: 1rem;
    width: 100%;
    min-height: 38rem;
    flex-direction: row;
  }

  .pictures {
    flex: 1;
  }

  .headshot {
    height: 80%;
  }

  .text {
    width: 50%;
    padding: 22% 2rem 2rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .testimonialName, .ctaContainer {
    padding-top: 0.75rem;
  }

  .testimonialContainer :global(.swiper-slide) {
    width: 90%;
  }

  .ctaContainer {
    display: block;
  }

  .testimonialContainer :global(.swiper-autoheight) {
    height: auto;
  }
}

@media screen and (min-width: NEW_TABLET_PORTRAIT_MAX) {
  .title {
    margin: 0 0 1rem 6.75rem;
  }

  .container {
    min-height: 46rem;
  }

  .testimonialCard {
    padding-left: 3rem;
    padding-right: 3rem;
    min-height: 38rem;
  }

  .testimonialContainer {
    padding: 2rem 2rem 0.5rem;
    min-height: 46rem;
  }

  .text {
    padding-top: 10%;
  }

  .headshot {
    height: 75%;
  }
}