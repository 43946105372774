@value colors: 'styles/colors.module.css';
@value PURPLE, WHITE, NEUTRAL_GRAY_50 from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.container {
  width: 100%;
  background: NEUTRAL_GRAY_50;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.text {
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 500;
  margin: 1.5rem 1rem;
  max-width: 1224px;
  background-color: NEUTRAL_GRAY_50;
}

@media (min-width: TABLET_WIDTH) {
  .text {
    font-size: 3rem;
    line-height: 3.5rem;
    margin: 1.5rem;
  }
}

@media (min-width: DESKTOP_MED_WIDTH) {
  .text {
    font-size: 4.5rem;
    line-height: 5.5rem;
    margin: 4rem auto;
  }
}
