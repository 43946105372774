@value colors: 'styles/colors.module.css';
@value PURPLE, WHITE, SHADES_BLACK, BLUE_GRAYS_01, BLUE_GRAYS_04, BLUE_GRAYS_SEMI_BLACK from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.landingPagePlanContainer {
  font-family: 'Lato', sans-serif;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 14px;
  background: WHITE;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
  margin-top: 2.5rem;
}

.landingPageDesktopFsaBadgeContainer {
  display: none;
}

.landingPagePlanHeaderContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.landingPageTitleHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.title {
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 3.48px;
  background: linear-gradient(270deg, #E57054 0%, #85254E 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
}

.fsaBadgeContainer {
  display: flex;
}

.landingPagePriceHeader {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 24px;
  border-bottom: 2px solid BLUE_GRAYS_01;
}

.landingPageDailyPrice {
  font-size: 34px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  background: linear-gradient(270deg, #E57054 0%, #85254E 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.landingPagePricingDescription {
  color: BLUE_GRAYS_04;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.landingPagePlanBodyContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  gap: 24px
}

.landingPageBulletPointsSection1 {
  padding-bottom: 24px;
  border-bottom: 2px solid BLUE_GRAYS_01;
  width: 100%;
}

.landingPageBulletPointsSection2 {
  width: 100%;
}

.landingPageBulletPointsSection1 .landingPageBulletPointsSectionText,
.landingPageBulletPointsSection2 .landingPageBulletPointsSectionText {
  color: BLUE_GRAYS_SEMI_BLACK;
}

@media screen and (min-width: DESKTOP_WIDTH) {
  .landingPagePlanContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding: 60px;
    gap: 40px;
  }

  .landingPageDesktopFsaBadgeContainer {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    padding: 14px 20px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 0px 6px 0px 20px;
    background: #EBFFE8;
  }

  .landingPageDesktopFsaBadgeContainer > span {
    color: #007930;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -0.36px;
  }

  .landingPagePlanHeaderContainer {
    display: flex;
    justify-content: space-between;
    gap: unset;
  }

  .title {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 3.84px;
  }

  .fsaBadgeContainer {
    display: none;
  }

  .landingPagePriceHeader {
    flex-direction: column;
    width: 100%;
    border-bottom: unset;
    gap: 24px;
    padding-bottom: unset;
  }

  .landingPageDailyPrice > span:first-child {
    font-size: 80px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -1.6px;
  }

  .landingPageDailyPrice > span:last-child {
    font-size: 30px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.6px;
  }


  .landingPagePricingDescription {
    font-size: 18px;
    font-weight: 800;
    padding-top: 24px;
    width: 100%;
    border-top: 2px solid BLUE_GRAYS_01;
    color: BLUE_GRAYS_SEMI_BLACK;
  }

  .landingPageBulletPointsSection1 .ladingPageBulletPoints,
  .landingPageBulletPointsSection2 .ladingPageBulletPoints {
    margin-bottom: 24px;
  }

  .landingPageBulletPointsSection1 .ladingPageBulletPoints:last-child,
  .landingPageBulletPointsSection2 .ladingPageBulletPoints:last-child {
    margin-bottom: 0;
  }

  .landingPageBulletPointsSection1 .ladingPageBulletPointsCheck,
  .landingPageBulletPointsSection2 .ladingPageBulletPointsCheck {
    height: 20px;
    width: 20px;
  }

  .landingPageBulletPointsSection1 .landingPageBulletPointsSectionText,
  .landingPageBulletPointsSection2 .landingPageBulletPointsSectionText {
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    color: BLUE_GRAYS_SEMI_BLACK;
  }
}