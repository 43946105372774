@value colors: 'styles/colors.module.css';
@value PURPLE, WHITE, SHADES_BLACK from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  width: 100%;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  width: 100%;
  padding-bottom: 48px;
  background-color: WHITE;
  margin-top: 112px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

.card:hover {
  box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.15);
}

.card:hover .overlay {
  opacity: 0.4;
}

.card:first-child {
  margin-top: 144px;
}

div.profileImage {
  position: relative;
  margin-top: -112px;
  width: 224px;
  height: 224px;
  border: 4px solid WHITE;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.11);
  margin-bottom: 32px;
  background-color: WHITE;
  border-radius: 50%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(80.11deg, #830051 -12.82%, #ff6d42 109.97%);
  opacity: 0;
  /* transition: 0.2s ease; */
}

.img {
  color: transparent;
  height: 100%;
  object-fit: cover;
  text-align: center;
  text-indent: 10000px;
  width: 100%;
}

h3.title {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
}

span.subtitle {
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: SHADES_BLACK;
}

span.tagline {
  margin-top: 1.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: PURPLE;
  font-weight: 500;
}

@media screen and (min-width: TABLET_WIDTH) {
  .card {
    width: 300px;
  }
}
