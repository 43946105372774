@value colors: 'styles/colors.module.css';
@value PURPLE, WHITE from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.container {
  padding: 4rem 2rem;
  background-color: PURPLE;
  color: WHITE;
}

.content {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.header {
  font-size: 2rem;
  line-height: 2.25rem;
}

.icon {
  color: WHITE;
}

.error {
  display: flex;
  position: absolute;
  color: WHITE;
  left: 50%;
}
