@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_WIDTH from breakpoints;

.container {
  padding: 24px;
  padding-bottom: 0;
  max-width: 1440px;
  margin: 0 auto;
}

.row {
  display: flex;
  width: 100%;
}

.wrap {
  compose: row;
  flex-wrap: wrap;
}

.wrapReverse {
  compose: row;
  flex-wrap: wrap-reverse;
}

@media screen and (min-width: DESKTOP_WIDTH) {
  .container {
    margin: 0 auto;
    padding: 0;
  }

  .row {
    padding: 108px;
    align-items: center;
  }
}
