@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.container {
  display: flex;
  position: sticky;
  top: 0;
  background: WHITE;
  align-items: center;
  justify-content: space-between;
  height: 3.25rem;
  padding: 1rem;
  z-index: 100;
  scroll-behavior: smooth;
  margin-top: 0.5rem;
}

.banner {
  top: 50px;
}

.shadow {
  box-shadow: 0 1px 0 #e2e8f0;
}

.logoContainer {
  display: flex;
}

.logo {
  height: 1.25rem;
}

@media (min-width: TABLET_WIDTH) {
  .container {
    padding: 1.5rem;
  }
}

@media (min-width: DESKTOP_WIDTH) {
  .container {
    height: 5.875rem;
    padding: 2.25rem 1.5rem;
  }

  .logo {
    height: 1.5rem;
  }
}

@media (min-width: DESKTOP_MED_WIDTH) {
  .container {
    padding: 2.25rem 1.5rem;
  }
}

@media (min-width: 1440px) {
  .container {
    padding: 2.25rem 3rem;
  }
}

@media (min-width: 1920px) {
  .container {
    padding: 2.25rem 1.5rem;
  }
}