@value colors: 'styles/colors.module.css';
@value NEUTRAL_GRAY_50, NEUTRAL_GRAY_300, SUCCESS_600, PURPLE, ACCENT_GREY, SILVER from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value TABLET_WIDTH from breakpoints;

.container {
  /* border: 1px solid NEUTRAL_GRAY_300; */
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  margin: 2rem;
  /* padding: 1rem; */
}

.mission {
  display: flex;
  font-size: 1.125rem;
  line-height: 1.75rem;
  max-width: 600px;
  margin-top: 1.5rem;
}

@media only screen and (min-width: 992px) {
  .mission {
    font-size: 1.25rem;
    line-height: 2rem;
    max-width: 768px;
  }
}
