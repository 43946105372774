@value colors: 'styles/colors.module.css';
@value PURPLE, WHITE, SHADES_BLACK, BLUE_GRAYS_01, BLUE_GRAYS_04, BLUE_GRAYS_SEMI_BLACK from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;
@value gradients: 'components/dashboard/clients/coach-home/gradients.module.css';
@value greenGradient, redGradient from gradients;

.landingPagePlanContainer {
  position: relative;
  flex: 1;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 14px;
  background: WHITE;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
  margin-top: 4.5rem;
  border: 4px solid#FFF;
}

.trialPlanContainer {
  margin-top: 1rem;
  gap: 0.5rem;
}

.trialPlanContainer > * > span {
  color: BLUE_GRAYS_SEMI_BLACK;
}

div.lessTopMargin {
  margin-top: 0;
}

.lessBottomMargin {
  margin-bottom: 1rem;
}

.mobileContainer {
  margin-top: 2rem;
  position: relative;
}

.fyiCallout {
  composes: redGradient;
  position: absolute;
  font-size: 0.875rem;
  font-weight: 800;
  line-height: 1.25rem;
  display: flex;
  z-index: 1;
  text-align: center;
  top: 0;
  width: 100%;
  justify-content: center;
}

.arrow {
  position: absolute;
  transform: translateY(1rem);
  margin-right: 0.5rem;
  flex-shrink: 0;
  left: 0;
  top: 1rem;
}

.programCard {
  flex: 2;
  border: 4px solid #FFF;
  background: #F5F8FB;
}

.desktopContainer {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.7rem;
}

.landingPageDesktopFsaBadgeContainer {
  display: none;
}

.landingPagePlanHeaderContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.landingPageTitleHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.title {
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.18rem;
  background: linear-gradient(270deg, #E57054 0%, #85254E 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
}

.trialTitle {
  margin-bottom: 1.5rem;
}

.fsaBadgeContainer {
  white-space: nowrap;
  display: flex;
}

.landingPagePriceHeader {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 24px;
  border-bottom: 2px solid BLUE_GRAYS_01;
  flex: 1;
}

.landingPageDailyPrice {
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  background: linear-gradient(270deg, #E57054 0%, #85254E 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.vert {
  display: flex;
  flex-direction: column;
}

.landingPagePricingDescription {
  color: BLUE_GRAYS_04;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.semiBlack {
  color: BLUE_GRAYS_SEMI_BLACK;
}

.landingPagePricingDescriptionDesktop {
  color: #030017;
  font-size: 1rem;
  font-weight: 800;
}

.landingPagePlanBodyContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  gap: 24px
}

.landingPageBulletPointsSection1 {
  padding-bottom: 24px;
  border-bottom: 2px solid BLUE_GRAYS_01;
  width: 100%;
}

.noBorder {
  border: unset;
}

.landingPageBulletPointsSection2 {
  width: 100%;
}

.landingPageBulletPointsSection1 .landingPageBulletPointsSectionText {
  color: BLUE_GRAYS_SEMI_BLACK;
}

.landingPageBulletPointsSection2 .landingPageBulletPointsSectionText {
  color: #62697D;
}

.desktopArrow {
  padding-right: 1rem;
}


@media screen and (min-width: DESKTOP_WIDTH) {
  span.landingPageBulletPointsSectionText {
    font-size: 1rem;
  }

  .price {
    font-size: 5rem;
  }

  .landingPagePricingDescription {
    font-size: 0.875rem;
  }
  .fyiCallout {
    top: -3.75rem;
    white-space: nowrap;
    left: -50%;
    flex-direction: column;
    align-items: flex-end;
    width: unset;
  }

  .landingPagePlanContainer {
    margin-top: 3.5rem;
  }
}