@value colors: 'styles/colors.module.css';
@value SHADES_BLACK, WHITE, NEUTRAL_GRAY_50, LINK_BLUE from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value NEW_TABLET_PORTRAIT_MAX, NEW_MOBILE_MAX from breakpoints;

.container {
    display: flex;
    height: fit-content;
    width: 100%;
    position: relative;
    background: WHITE;
}

.content {
    display: flex;
    flex-direction: column;
    padding: 2rem 10rem;
    width: 100%;
}

.title {
    font-size: 2.5rem;
    line-height: 3rem;
    color: SHADES_BLACK;
    font-weight: 500;
    text-align: center;
    margin-bottom: 1rem;
}

.subTitle {
    color: SHADES_BLACK;
    font-size: 1.125rem;
    line-height: 1.688rem;
    margin-bottom: 1rem;
    font-weight: 400;
}

.grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: min-content;
}

.gridItem {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.gridItemLink {
    text-decoration: none;
    font-weight: 500;
    color: SHADES_BLACK
}

.gridItemLink:hover,
.gridItemLink:focus {
    color: LINK_BLUE;
}

@media screen and (max-width: NEW_TABLET_PORTRAIT_MAX) {
    .container {
        height: auto;
    }

    .content {
        padding: 3.5rem;
    }

    .title, .subTitle {
        margin-bottom: 1.75rem;
    }
}

@media screen and (max-width: NEW_MOBILE_MAX) {
    .container {
        position: relative;
        display: block;
    }

    .content {
        position: relative;
        display: block;
        padding: 1.5rem;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: min-content;
    }
}
