@value colors: 'styles/colors.module.css';
@value WHITE, ORANGE, ACCENT_GREY from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value MOBILE_WIDTH_MAX from breakpoints;

.getStarted {
  padding: 3.8rem;
  background-color: WHITE;
}

.content {
  max-width: 1100px;
  margin: 0 auto;
}

.main {
  display: flex;
  align-items: center;
}

.headers {
  width: 50%;
}

.header {
  composes: header from 'styles/homepage.module.css';
  color: ORANGE;
}

.form {
  width: 50%;
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .getStarted {
    padding: 3rem 1.5rem;
  }

  .main {
    flex-wrap: wrap;
  }

  .headers {
    width: 100%;
  }

  .form {
    width: 100%;
    max-width: 600px;
    padding-top: 3rem;
    margin: 0 auto;
  }
}

@media screen and (max-width: 330px) {
  .getStarted {
    padding: 3rem 1rem;
  }
}
