@value colors: 'styles/colors.module.css';
@value PURPLE, WHITE, NEUTRAL_GRAY_50, NEUTRAL_GRAY_100 from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.container {
  padding: 2.5rem 0;
  background-color: NEUTRAL_GRAY_50;
}

.coachBackground {
  background-color: NEUTRAL_GRAY_100;
}

.container::before {
  content: ' ';
  display: block;
  height: 42px;
  margin-top: -42px;
  visibility: hidden;
}

.standardPricing {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 1.75rem;
  line-height: 2.5rem;
  font-weight: 700;
  text-align: center;
}

.terms {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 2.5rem 0;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 500;
}

.planSelection {
  padding: 0 24px;
}

.desktop {
  display: none;
}

@media (min-width: TABLET_WIDTH) {
  .terms {
    flex-direction: row;
    justify-content: center;
  }

  .terms > span {
    padding: 0 0.5rem;
    border-right: 1px solid #cbd5e1;
  }

  .terms > span:last-child {
    border-right: none;
  }
}

@media (min-width: DESKTOP_MED_WIDTH) {
  .container {
    padding: 4rem 0;
  }

  .container::before {
    content: ' ';
    display: block;
    height: 76px;
    margin-top: -76px;
    visibility: hidden;
  }

  .title {
    font-size: 3rem;
    line-height: 4rem;
    margin-bottom: 2.5rem;
  }

  .planSelection {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mobile {
    display: none;
  }

  .desktop {
    display: flex;
  }
}