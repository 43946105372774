@value colors: 'styles/colors.module.css';
@value PURPLE, ORANGE, YELLOW from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value MOBILE_WIDTH_MAX, TABLET_WIDTH from breakpoints;

.reviewsSection {
  padding: 4.0625rem 3.125rem;
  background-color: #efefef;
}

.header {
  composes: header from 'styles/homepage.module.css';
  color: PURPLE;
}

.swiperWrapper {
  position: relative;
  margin: 0 -3.125rem;
}

.reviewsSection :global(.swiper) {
  padding: 4.6875rem 4.6875rem 4.6875rem 1.5625rem;
}

.overlay {
  position: absolute;
  width: 2.8125rem;
  height: 100%;
  top: 0;
  z-index: 2;
}

.overlayNext {
  composes: overlay;
  background: linear-gradient(270deg, #efefef, hsla(0, 0%, 93.7%, 0));
  right: 0;
}

.overlayPrevious {
  composes: overlay;
  background: linear-gradient(270deg, hsla(0, 0%, 93.7%, 0), #efefef);
  left: 0;
}

.reviewContent {
  background: WHITE;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
  border-radius: 0.625rem;
  padding: 1.375rem;
}

.reviewTextWrapper {
  margin-bottom: 0.5rem;
}

.reviewInfo {
  display: flex;
  color: #707070;
}

.appStoreIconWrapper {
  padding-top: 0.1rem;
}

.appStoreIcon {
  width: 40px;
  margin-right: 1rem;
}

.avatarWrapper {
  float: left;
  width: 62px;
  height: 62px;
  margin-right: 0.8125rem;
  border-radius: 50%;
  overflow: hidden;
  background-color: #c4c4c4;
}

.avatar {
  width: 100%;
  height: 100%;
}

.avatarImage {
  margin: 0 auto;
  min-height: 100%;
}

.swiperButton {
  composes: unstyled from 'styles/buttons.module.css';
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}

.nextButton {
  composes: swiperButton;
  right: 1rem;
}

.previousButton {
  composes: swiperButton;
  left: 1rem;
}

.arrowIcon svg {
  stroke: ORANGE;
}

.starsContainer {
  margin: 1rem 0;
}

.star {
  color: #f59e0b;
  margin-right: 2px;
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .reviewsSection {
    padding: 3rem 1.5rem;
  }

  .header {
    line-height: 4rem;
    margin-bottom: 2.5rem;
  }

  .swiperWrapper {
    margin: 0 -1.5rem;
  }

  .reviewTextWrapper,
  .reviewInfo {
    font-size: 1.15rem;
  }
}
