@value colors: 'styles/colors.module.css';
@value WHITE, ACCENT_GREY from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 2.5rem 1.5rem;
  padding-top: 1rem;
  background-color: WHITE;
  margin: 0 auto;
}

.content {
  display: grid;
  grid-template-rows: repeat(3, minmax(0, 1fr));
  grid-template-columns: repeat(3, minmax(0, 1fr));
  align-content: center;
  align-items: center;
  justify-items: center;
  max-width: 1224px;
  width: 100%;
}

.header {
  grid-column: span 3 / span 3;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  width: 100%;
}

.logo {
  position: relative;
  width: 60px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  opacity: 50%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: TABLET_WIDTH) {
  .content {
    display: grid;
    grid-template-rows: repeat(2, minmax(0, 1fr));
    grid-template-columns: repeat(6, minmax(0, 1fr));
    align-content: center;
    align-items: center;
    justify-items: center;
  }

  .header {
    grid-column: span 7 / span 7;
  }

  .logo {
    width: 80px;
  }
}

@media screen and (min-width: DESKTOP_WIDTH) {
  .content {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    grid-template-rows: none;
    align-content: center;
    align-items: center;
    justify-items: center;
    margin-left: 1rem;
  }

  .header {
    grid-column: span 1 / span 1;
    font-size: 1.688rem;
    line-height: 2.5rem;
    justify-content: start;
    margin-bottom: 0;
  }

  .logo {
    margin: 0;
    opacity: 50%;
  }

  .logo:last-child {
    margin-right: 0;
  }
}
