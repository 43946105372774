@value colors: 'styles/colors.module.css';
@value ORANGE, WHITE from colors;

.getStartedLink {
  composes: unstyled from 'styles/links.module.css';
}

.getStartedButton {
  composes: baseV2 from 'styles/buttons.module.css';
  composes: mediumV2 from 'styles/buttons.module.css';
  composes: solidOrange from 'styles/buttons.module.css';
  padding: 0.65rem 1.3rem;
  min-width: 13.5rem;
}

.buttonBase {
  position: relative;
}

.error {
  margin-top: 0.5rem;
  color: ORANGE;
}

.invisible {
  opacity: 0;
}

.spinnerWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  color: WHITE !important;
}
