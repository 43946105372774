@value colors: 'styles/colors.module.css';
@value SILVER from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value MOBILE_WIDTH_MAX from breakpoints;

.text {
  color: SILVER;
}

.testimonialWrapper {
  flex: 1;
  padding-left: 6.5rem;
}

.imageWrapper {
  width: 14rem;
  height: 14rem;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 3rem;
}

.cmsImage {
  width: 100%;
  height: 100%;
  object-position: center;
}

.image {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.testimonialText {
  composes: text;
  font-size: 1.5rem;
}

.name {
  composes: testimonialText;
  font-weight: 500;
}

.title {
  composes: testimonialText;
  margin-bottom: 2.5rem;
}

.testimonial {
  composes: testimonialText;
  white-space: pre-wrap;
  max-width: 34rem;
}

.coachTestimonial {
  font-size: 3rem;
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .testimonialWrapper {
    padding: 0;
    display: flex;
    margin-top: 3rem;
  }

  .imageWrapper {
    width: 5rem;
    height: 5rem;
    flex-shrink: 0;
    margin-right: 1.5rem;
  }

  .testimonialTextWrapper {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  .testimonialText {
    font-size: 1.125rem;
  }

  .testimonial {
    margin-bottom: 1.5rem;
  }

  .title {
    margin-bottom: 0;
  }

  .coachTestimonial {
    font-size: 3rem;
  }
}
