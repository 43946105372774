@value colors: 'styles/colors.module.css';
@value SILVER from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value TABLET_WIDTH from breakpoints;

.container {
  margin: 2rem 0;
}

.quote {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.quoteIcon {
  width: 50px;
}

.quoteText {
  display: flex;
  text-align: center;
  margin: 2rem;
  font-size: 1.875rem;
}

@media only screen and (min-width: 992px) {
  .container {
    margin-top: 4rem;
  }

  .quoteText {
    font-size: 3rem;
  }
}
