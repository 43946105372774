@value colors: 'styles/colors.module.css';
@value WHITE, BLACK, NEUTRAL_GRAY_700, NEUTRAL_GRAY_300 from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.select {
  width: 100%;
  color: BLACK;
}

.customContainer {
  color: hsl(0, 0%, 20%);
  grid-area: 1/1/2/3;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}

.customContainer .label {
  font-size: 0.75rem;
  font-weight: 400;
  color: NEUTRAL_GRAY_700;
}

.select :global(.s__control) {
  width: 100%;
  padding: 0 1rem;
  border: 1px solid NEUTRAL_GRAY_300;
  align-items: center;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 40px;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
  background-color: WHITE;
}

.select :global(.s__control--menu-is-open) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select :global(.s__dropdown-indicator) {
  color: #475569;
}

.select :global(.s__control--menu-is-open .s__dropdown-indicator) {
  color: #475569;
  transform: rotate(-180deg);
}

.select :global(.s__value-container) {
  padding: 0;
}

.select :global(.s__indicator-separator) {
  display: none;
}

.select :global(.s__menu) {
  top: unset;
  width: 100%;
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background: linear-gradient(80.11deg, #830051 -12.82%, #ff6d42 109.97%);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.select :global(.s__menu-list) {
  max-height: 400px;
  overflow-y: auto;
  background-color: WHITE;
  margin-top: 2px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.select :global(.s__option) {
  padding: 12px 16px;
}

.select :global(.s__option--is-disabled) {
  display: none;
}

.select :global(.s__option--is-selected) {
  color: BLACK;
  font-weight: 500;
  background-color: transparent;
}

.select :global(.s__option--is-focused) {
  color: BLACK;
  font-weight: 500;
  background-color: transparent;
}

@media screen and (min-width: TABLET_WIDTH) {
  .select {
    width: 350px;
  }

  .select :global(.s__menu) {
    width: 350px;
  }

  .select :global(.s__menu-list) {
    max-height: 350px;
  }
}

@media screen and (min-width: DESKTOP_MED_WIDTH) {
  .select :global(.s__control) {
    height: 56px;
  }

  .select {
    width: 450px;
  }

  .select :global(.s__menu) {
    width: 450px;
  }
}
