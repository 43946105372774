@value colors: 'styles/colors.module.css';
@value WHITE from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value NEW_TABLET_PORTRAIT from breakpoints;

.coachHeroProfileSection {
  background-color: WHITE;
  padding-bottom: 1rem;
}

.coachHeroProfileSection * {
  font-family: 'Lato', sans-serif !important;
}

.fullPadding {
  margin-top: 175px;
}

.minimalPadding {
  margin-top: 0;
}

.coachMatch {
  font-size: 1.25rem;
  max-width: 1200px;
  margin: 0 auto;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  margin: 2rem;
}

.trainButton {
  composes: root primary from 'components/design-system/button/styles.module.css';
  border: 1px solid rgba(0, 0, 0, 0) !important;
  border-radius: 0.25rem !important;
  font-size: 1.125rem !important;
  font-weight: 500 !important;
  line-height: 1.75rem !important;
  padding: 0.625rem 1.5rem !important;
  width: 100%;
}

@media screen and (min-width: NEW_TABLET_PORTRAIT) {
  .coachHeroProfileSection {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
  }

  .minimalPadding {
    margin-top: 24px;
  }
}
