@value colors: 'styles/colors.module.css';
@value WHITE, ORANGE, ACCENT_GREY from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value MOBILE_WIDTH_MAX from breakpoints;

.container {
  background-color: WHITE;
  padding: 5rem 3.125rem;
}

.content {
  max-width: 1500px;
  margin: 0 auto;
}

.details {
  display: flex;
  margin: 0 -1rem;
}

.detail {
  width: 33.3333%;
  text-align: center;
  padding: 0 1rem;
}

.detailEmoji {
  display: flex;
  justify-content: center;
  height: 16rem;
  margin-bottom: 3rem;
}

.text {
  max-width: 315px;
  margin: 0 auto;
}

.detailText {
  composes: text;
  font-size: 1.625rem;
  font-weight: 500;
  margin-bottom: 3rem;
}

.detailSubText {
  composes: text;
  font-size: 1.375rem;
}

.image {
  object-fit: contain;
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .container {
    padding: 3rem 1.5rem;
  }

  .details {
    flex-wrap: wrap;
  }

  .detail {
    width: 100%;
  }

  .detail:not(:last-child) {
    margin-bottom: 4rem;
  }

  .detailEmoji {
    margin-bottom: 2rem;
    max-height: none;
  }

  .image {
    width: 100%;
    max-width: 18rem;
  }

  .detailText {
    margin-bottom: 2rem;
  }
}
