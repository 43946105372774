@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.container {
  margin-left: 1rem;
  margin-right: 1rem;
  max-width: 940px;
  font-size: 1rem;
  line-height: 1.75;
  margin-bottom: 100px;
  padding-bottom: 4rem;
  padding-top: 2rem;
}

.container * {
  font-family: 'Lato', sans-serif;
}


.container p {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.container strong {
  font-weight: bold;
}

.container em {
  font-style: italic;
}


.container blockquote {
  margin-top: 2em;
  margin-bottom: 2em;
  padding-left: 1.25em;
}


.container h1 {
  font-size: 2.25em;
  margin-top: 0;
  margin-bottom: 2em;
  line-height: 1.1111111;
  font-weight: 500;
  text-align: center;
}


.container h2 {
  font-size: 1.5em;
  margin-top: 3em;
  margin-bottom: 1.5em;
  line-height: 1.3333333;
}


.container h3 {
  font-size: 1.25em;
  margin-top: 2em;
  margin-bottom: 0.75em;
  line-height: 1.6;
}


.container h4 {
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  line-height: 1.5;
}


.container img {
  margin-top: 2em;
  margin-bottom: 2em;
}


.container video {
  margin-top: 2em;
  margin-bottom: 2em;
}


.container figure {
  margin-top: 2em;
  margin-bottom: 2em;
}


.container figure>* {
  margin-top: 0;
  margin-bottom: 0;
}


.container figcaption {
  font-size: 0.875em;
  line-height: 1.4285714;
  margin-top: 0.75em;
}


.container code {
  font-size: 0.875em;
}


.container h2 code {
  font-size: 1.3125em;
}


.container h3 code {
  font-size: 1.125em;
}


.container pre {
  font-size: 0.875em;
  line-height: 1.7142857;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  border-radius: 0.375rem;
  padding-top: 0.75em;
  padding-right: 1em;
  padding-bottom: 0.75em;
  padding-left: 1em;
}


.container ol {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: decimal;
}


.container ul {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: disc;
}


.container li {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}


.container ol>li {
  padding-left: 0.375em;
}


.container ul>li {
  padding-left: 0.375em;
}


.container>ul>li p {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}


.container>ul>li>*:first-child {
  margin-top: 1.25em;
}


.container>ul>li>*:last-child {
  margin-bottom: 1.25em;
}


.container>ol>li>*:first-child {
  margin-top: 1.25em;
}


.container>ol>li>*:last-child {
  margin-bottom: 1.25em;
}

.container ol ol {
  list-style-type: lower-alpha;
}


.container hr {
  margin-top: 3em;
  margin-bottom: 3em;
}


.container hr+* {
  margin-top: 0;
}


.container h2+* {
  margin-top: 0;
}


.container h3+* {
  margin-top: 0;
}


.container h4+* {
  margin-top: 0;
}


.container table {
  font-size: 0.875em;
  line-height: 1.7142857;
}


.container thead th {
  padding-right: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0.5em;
}


.container thead th:first-child {
  padding-left: 0;
}


.container thead th:last-child {
  padding-right: 0;
}

@media screen and (min-width: TABLET_WIDTH) {
  .container {
    margin-left: auto;
    margin-right: auto;
  }
}