@value colors: 'styles/colors.module.css';
@value SILVER, PURPLE, WHITE, SOLID_BRICK_RED from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value MOBILE_WIDTH_MAX, TABLET_WIDTH, DESKTOP_LG_WIDTH from breakpoints;

.pricingSection {
  display: flex;
  background-color: SOLID_BRICK_RED;
}

.imageWrapper {
  flex: 2.5;
}

.image {
  width: 100%;
  height: 100%;
}

.main {
  width: 50%;
  padding: 4.75rem 3.125rem;
  flex: 1 0 auto;
}

.header {
  composes: header from 'styles/homepage.module.css';
  margin-bottom: 2.75rem;
  color: SILVER;
}

.subHeader {
  font-size: 2.375rem;
  margin-bottom: 4rem;
  color: WHITE;
}

.points {
  max-width: 35rem;
  margin-bottom: 6.875rem;
}

.point {
  display: flex;
}

.point:not(:last-child) {
  margin-bottom: 2.1875rem;
}

.checkIcon {
  width: 1.625rem;
  margin-right: 1rem;
  height: max-content;
  margin-top: 0.1rem;
  flex-shrink: 0;
}

.pointText {
  color: WHITE;
  font-size: 1.125rem;
}

.pointTextPrimary {
  composes: pointText;
  font-weight: 500;
}

.pointTextSecondary {
  composes: pointText;
}

.getStartedLink {
  composes: unstyled from 'styles/links.module.css';
}

.getStartedButton {
  composes: baseV2 from 'styles/buttons.module.css';
  composes: mediumV2 from 'styles/buttons.module.css';
  composes: solidOrange from 'styles/buttons.module.css';
  padding: 0.65rem;
  width: 15.75rem;
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .pricingSection {
    display: block;
  }

  .imageWrapper {
    display: none;
  }

  .main {
    width: 100%;
    padding: 3rem 1.5rem;
  }

  .header {
    line-height: 4.4rem;
    margin-bottom: 2rem;
  }

  .subHeader {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .points {
    margin-bottom: 4rem;
  }
}

@media screen and (min-width: DESKTOP_LG_WIDTH) {
  .main {
    padding-left: calc((100% - 1100px) / 2);
  }
}