button.cta {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 500;
  padding: 0.625rem 1.5rem;
  height: 3rem;
}

button.cta > svg {
  margin-left: 0.5rem;
}

.spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
