@value breakpoints: 'styles/breakpoints.module.css';
@value TABLET_WIDTH, NEW_MOBILE_MAX from breakpoints;

.container {
  display: block;
  position: absolute;
  top: 0;
  left: 1rem;
  right: 1rem;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  border-radius: 8px;
  overflow: hidden;
}

.logo {
  position: absolute;
  height: 45px;
  padding-top: 1rem;
  padding-left: 1rem;
  z-index: 1;
}

.valueProps {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: absolute;
  align-items: center;
  top: 15%;
  left: 5%;
}

.valueProp {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  border-radius: 1.875rem;
  border: 1px solid #FFF;
  background: rgba(0, 0, 0, 0.20);
  padding: 0.5rem 1.25rem 0.5rem 0.75rem;
  color: #FFF;
  font-weight: 800;
  font-size: 0.875rem;
}

.fullScreen {
  left: 0;
  right: 0;
  border-radius: 0;
}

.video {
  composes: cover from 'styles/position.module.css';
  width: 100%;
}

.overlay {
  composes: cover from 'styles/position.module.css';
  background: linear-gradient(180deg, rgba(131, 0, 81, 0) 0%, #010101 76.5%),
    linear-gradient(
      80.11deg,
      rgba(131, 0, 81, 0.6) -12.82%,
      rgba(255, 109, 66, 0.6) 109.97%
    );
}

@media (min-width: NEW_MOBILE_MAX) {
  .logo {
    height : 60px;
  }
}

@media (min-width: TABLET_WIDTH) {
  .container {
    left: 1.5rem;
    right: 1.5rem;
  }

  .fullScreen {
    left: 0;
    right: 0;
  }

  .valueProp {
    font-size: 1.125rem;
  }

  .overlay {
    background: linear-gradient(
        203.3deg,
        rgba(131, 0, 81, 0) 16.3%,
        #000000 100%,
        #44042c 90.94%
      ),
      linear-gradient(
        80.11deg,
        rgba(131, 0, 81, 0.6) -12.82%,
        rgba(255, 109, 66, 0.6) 109.97%
      );
  }
}
