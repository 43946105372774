@value colors: 'styles/colors.module.css';
@value WHITE, SILVER, ORANGE from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_WIDTH, TABLET_WIDTH, MOBILE_WIDTH from breakpoints;

.heroSection {
  height: 95vh;
  max-height: 750px;
  min-height: 600px;
  overflow: hidden;
  width: 100%;
  position: relative;
}

.main {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 10rem 3.125rem;
  height: 100%;
  align-items: flex-start;
}

.cover {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.heroImage {
  composes: cover;
}

.heroImageOverlay {
  composes: cover;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.65) 0%,
    rgba(0, 0, 0, 0) 45%
  );
}

.headerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  max-width: 38rem;
  /* align-items: center; */
}

.header {
  color: WHITE;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1.2rem;
}

@media screen and (max-width: TABLET_WIDTH) {
  .main {
    padding: 2.25rem 3.125rem;
  }
}

@media screen and (max-width: MOBILE_WIDTH) {
  .heroSection {
    max-height: 650px;
  }

  .header {
    font-size: 2.1rem;
    line-height: 2.75rem;
    padding-right: 25px;
  }
}

.subHeader {
  color: WHITE;
  font-size: 1.7rem;
  margin-top: 1.2rem;
}

.form {
  margin-top: 5rem;
  padding-right: 2rem;
  text-align: center;
}

.getStartedLink {
  margin-top: 2.4rem;
}

.footer {
  display: flex;
  justify-content: flex-end;
}

.learnMoreButton {
  composes: unstyled from 'styles/buttons.module.css';
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.125rem;
}

.downIcon {
  margin-left: 0.5625rem;
  width: 1.4375rem;
}

.appStoreLinks {
  transform: translate(-1.25rem, -1.25rem);
}

.appStoreLink {
  composes: unstyled from 'styles/links.module.css';
  display: block;
  color: SILVER !important;
  font-size: 1.25rem !important;
}

.appStoreLink:first-child {
  margin-bottom: 0.625rem;
}

.appStoreIcon {
  margin-right: 8px;
  width: 12px;
}

.appStoreRating {
  margin-top: 0.3rem;
}

@media screen and (max-width: DESKTOP_WIDTH) {
  .cover {
    position: fixed;
  }

  .heroImageOverlay {
    background: rgba(0, 0, 0, 0.3);
  }

  .form {
    margin-top: 3rem;
  }

  .getStartedLink {
    margin-top: 3rem;
  }

  .main {
    padding: 1.5rem 1.5rem 2rem;
  }

  .headerWrapper {
    margin-top: 4rem;
  }

  .header,
  .subHeader {
    max-width: 24rem;
  }

  .header {
    font-size: 1.9rem;
  }

  .subHeader {
    font-size: 1.5rem;
    line-height: 1.25;
  }

  .form {
    padding-right: 0;
  }

  .footer {
    justify-content: flex-start;
    align-items: flex-end;
  }

  .appStoreLinks {
    transform: none;
    display: flex;
  }

  .appStoreLink:first-child {
    margin-bottom: 0;
    margin-right: 2rem;
  }
}

@media screen and (max-width: 345px) {
  .heroSection {
    min-height: 680px;
  }

  .form {
    margin-left: -1rem;
    margin-right: -1rem;
  }
}
