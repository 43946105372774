.name {
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  align-items: center;
}

@media only screen and (min-width: 992px) {
  .name {
    font-size: 2.5rem;
  }
}
