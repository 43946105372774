@value colors: 'styles/colors.module.css';
@value NEUTRAL_GRAY_50, NEUTRAL_GRAY_900, WHITE, SILVER, CHARCOAL, ORANGE from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value MOBILE_WIDTH_MAX, TABLET_WIDTH from breakpoints;

@value elevations: '~styles/elevations.module.css';
@value ELEVATION_BORDER_BOTTOM, ELEVATION_XS from elevations;

@value TRANSITION: all 0.25s linear;

.transition {
  transition: TRANSITION;
}

.nav {
  color: CHARCOAL !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 5;
  top: 0;
  width: 100%;
  padding: 3.125rem;
  background-color: transparent;
  box-shadow: ELEVATION_BORDER_BOTTOM;
  padding: 1.25rem 3.125rem;
}

.navScrolled {
  background-color: #fff;
  box-shadow: ELEVATION_XS;
  /* background-color: SILVER; */
  /* background-color: NEUTRAL_GRAY_50; */
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); */
}

.nav:not(.navScrolled) .spinner {
  color: ORANGE !important;
}

.navWithBanner {
  top: 50px !important;
}

.logoWrapper {
  position: relative;
  height: 2rem;
  width: 8.625rem;
}

.logo {
  position: absolute;
  height: 100%;
  opacity: 1;
  composes: transition;
}

.button {
  composes: baseV2 from 'styles/buttons.module.css';
  composes: smallV2 from 'styles/buttons.module.css';
  width: 48%;
  display: block;
}

.loginButton {
  composes: button;
  composes: solidPurple from 'styles/buttons.module.css';
}

.logoWhite {
  composes: logo;
}

.logoDark {
  composes: logo;
  width: 90%
}

.logoHidden {
  opacity: 0;
}

.bars {
  display: none;
}

.actions {
}

.link {
  composes: unstyled from 'styles/links.module.css';
  font-size: 1.25rem !important;
  composes: transition;
}

.link:not(:last-child) {
  margin-right: 2.5rem;
}

.link:hover {
  opacity: 0.5;
}

.navScrolled .link {
  color: CHARCOAL !important;
}

.getStartedLink {
  display: inline;
}

.getStartedLinkMobile {
  display: none;
}

.getStartedButton {
  composes: root primary from 'components/design-system/button/styles.module.css';
  border: 1px solid rgba(0, 0, 0, 0) !important;
  border-radius: 0.25rem !important;
  display: inline-block !important;
  font-size: 1.125rem !important;
  font-weight: 500 !important;
  line-height: 1.75rem !important;
  padding: 0.625rem !important;
  width: 12.75rem;
}

.getStartedButtonMobile {
  composes: getStartedButton;
  padding: 0.65rem 0.75rem;
  width: auto;
}

.getStartedButtonNotScrolled {
  composes: whiteOrange from 'styles/buttons.module.css';
}

.getStartedButtonScrolled {
  composes: solidOrange from 'styles/buttons.module.css';
}

.socialLinks {
  display: none;
}

@media screen and (max-width: 1350px) {
  .link:not(:last-child) {
    margin-right: 1.5rem;
  }
}

@media screen and (max-width: 1210px) {
  .link:not(:last-child) {
    margin-right: 1rem;
  }
}

@media screen and (max-width: 1140px) {
  .link {
    font-size: 1.1rem !important;
  }

  .link:not(:last-child) {
    margin-right: 0.75rem;
  }
}

@media screen and (max-width: 1050px) {
  .link {
    font-size: 1rem !important;
  }

  .link:not(:last-child) {
    margin-right: 0.5rem;
  }
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .logo {
    width: 85%;
  }

  .nav {
    padding: 1.5rem;
    transition: all 0.2s;
  }

  .navOpen {
    background-color: #fff;
  }

  .bars {
    composes: unstyled from 'styles/buttons.module.css';
    display: block;
    position: relative;
    width: 1.75rem;
    height: 1.25rem;
    margin-left: 2rem;
  }

  .bar {
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: NEUTRAL_GRAY_900;
    transition: all 0.2s;
    transform-origin: left center;
  }

  .navScrolled:not(.navOpen) .bar {
    background-color: NEUTRAL_GRAY_900;
  }

  .bar:nth-child(1) {
    top: 0;
    transform: translateY(-50%);
  }

  .bar:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
  }

  .bar:nth-child(3) {
    bottom: 0;
    transform: translateY(50%);
  }

  .navOpen .bar:nth-child(1) {
    transform: rotate(45deg) translateY(-50%);
  }

  .navOpen .bar:nth-child(2) {
    opacity: 0;
  }

  .navOpen .bar:nth-child(3) {
    transform: rotate(-45deg) translateY(50%);
  }

  .actions {
    z-index: -99;
    pointer-events: none;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: WHITE;
    transition: all 0.2s;
    padding: 6.25rem 1.5rem 1.5rem;
  }

  .link {
    display: block;
    font-size: 2.15rem !important;
    line-height: 1.6;
  }

  .navOpen .actions {
    opacity: 1;
    pointer-events: all;
  }

  .getStartedLink {
    display: none;
  }

  .getStartedLinkMobile {
    display: block;
    margin-left: auto;
  }

  .socialLinks {
    position: fixed;
    bottom: 1.5rem;
    left: 1.5rem;
    display: flex;
    z-index: -99;
    opacity: 0;
    transition: all 0.2s;
    pointer-events: none;
  }

  .socialLink {
    margin-right: 0.9375rem;
  }

  .socialIcon {
    width: 2.5rem;
    height: 2.5rem;
  }

  .navOpen .socialLinks {
    z-index: 1;
    opacity: 1;
    pointer-events: all;
  }
}

@media screen and (max-width: 370px) {
  .logoWrapper {
    width: 7.5rem;
  }

  .logo {
    width: 80%;
  }

  .getStartedButtonMobile {
    padding: 0.65rem 0.3rem;
  }

  .bars {
    margin-left: 0.5rem;
  }
}
