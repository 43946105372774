@value colors: 'styles/colors.module.css';
@value PURPLE, SILVER from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, MOBILE_WIDTH_MAX, TABLET_WIDTH, MOBILE_WIDTH from breakpoints;

.aboutSection {
  display: flex;
  background-color: PURPLE;
}

.imageWrapper {
  height: 100%;
  width: 40%;
  overflow: hidden;
}

@media screen and (min-width: MOBILE_WIDTH_MAX) and (max-width: DESKTOP_MED_WIDTH) {
  .aboutSection {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
  }
  .imageWrapper {
    height: auto;
    width: 40%;
  }
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .imageWrapper {
    width: 100%;
    height: 85vh;
  }
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top center;
  image-orientation: from-image;
}

.main {
  flex: 1;
  padding: 5.3125rem 4.6875rem 6rem;
  max-width: 55rem;
}

.top {
}

.header {
  composes: header from 'styles/homepage.module.css';
  margin-bottom: 1.625rem;
  color: SILVER;
}

.iconItems {
  display: flex;
  margin-bottom: 7rem;
}

.iconItem {
  flex: 1 1 auto;
  display: flex;
}

.iconItemContent {
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.icon {
  height: 2rem;
  width: auto;
  margin-right: 1rem;
}

.iconLabel {
  font-size: 1.5rem;
  color: SILVER;
  silver-space: nowrap;
}

@media screen and (max-width: TABLET_WIDTH) {
  .iconLabel {
    font-size: 1.2rem;
  }
  .icon {
    height: 1.5rem;
  }
}

.plusIcon {
  display: none;
  width: 2.125rem;
  margin: 0 3.3125rem;
  margin-bottom: 2.25rem;
}

.iconItem:last-child .plusIcon {
  display: none;
}

.textItems {
}

.textItem:not(:last-child) {
  margin-bottom: 2rem;
}

.text {
  color: SILVER;
  font-size: 1.4rem;
}

.textItemLabel {
  composes: text;
}

.textItemText {
  composes: text;
  font-weight: 300;
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .aboutSection {
    flex-direction: column-reverse;
    height: auto;
  }

  .main {
    padding: 3rem 1.5rem;
  }

  .text {
    font-size: 1.2rem;
  }

  .iconItems {
    margin-bottom: 5rem;
  }

  .icon {
    /*    height: 3.6rem;*/
  }

  .iconLabel {
    font-size: 1.15rem;
    white-space: nowrap;
  }

  .plusIcon {
    width: 1.5rem;
    margin: 0 0.5rem;
    margin-bottom: 2.25rem;
  }

  .imageWrapper {
    width: 100%;
    max-width: 100%;
  }

  .textItem:not(:last-child) {
    border-bottom: 1px solid rgba(242, 242, 242, 0.2);
    padding-bottom: 2rem;
  }
}

@media screen and (max-width: MOBILE_WIDTH) {
  .iconItems {
    display: block;
    margin-bottom: 3rem;
  }
  .icon {
    height: 2rem;
    width: 2rem;
  }
  .iconLabel {
    font-size: 1.5rem;
  }
  .iconItemContent {
    margin-bottom: 1.3rem;
  }
}
