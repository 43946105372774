@value colors: 'styles/colors.module.css';
@value PURPLE, WHITE from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH, NEW_MOBILE_MAX from breakpoints;

.container {
  display: flex;
  min-height: 565px;
  width: 100%;
  position: relative;
}

.fullScreen {
  height: 100% !important;
}

.banner {
  margin-top: 50px;
}

.content {
  display: flex;
  flex-direction: column;
  color: WHITE;
  justify-content: flex-end;
  margin: 0 1rem;
  padding: 1rem;
}

.contentFullScreen {
  padding-bottom: 5rem;
}

.headerContent {
  display: flex;
  flex-direction: column;
  color: WHITE;
  justify-content: flex-end;
  flex: 1;
}

.title {
  font-size: 1.875rem;
  line-height: 2rem;
  color: WHITE;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.subTitle {
  font-size: 1rem;
  line-height: 1.5rem;
  color: WHITE;
  margin-bottom: 0.5rem;
}

.mobileReviewsContainer {
  display: flex;
  margin-top: 2rem;
  opacity: 50%;
}

.mobileReviewsContainer > div {
  margin-right: 1rem;
  font-size: 0.875rem;
}

.mobileReviewsContainer > div:last-child {
  margin-right: 0;
}

.desktop {
  display: none;
}

@media (min-width: TABLET_WIDTH) {
  .container {
    min-height: 475px;
    height: 475px;
  }

  .content {
    padding: 0;
    margin-left: 4rem;
    margin-right: 4rem;
    margin-bottom: 3rem;
  }

  .title {
    font-size: 3rem;
    line-height: 3.5rem;
    white-space: pre-wrap;
  }

  .subTitle {
    width: 70%;
  }

  .mobileReviewsContainer {
    margin-top: 0;
  }

  .mobileReviewsContainer > div {
    margin-right: 1.5rem;
  }
}

@media (min-width: DESKTOP_WIDTH) {
  .container {
    height: calc(100vh - 9rem);
    min-height: 756px;
  }

  .mobileReviewsContainer > div {
    margin-right: 2.25rem;
  }
}

@media (max-width: NEW_MOBILE_MAX) {
  .fullScreen .title {
    font-size: 1.75rem;
    line-height: 2.25rem;
    font-weight: 700;
  }
}

@media (min-width: 1440px) {
  .content {
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    padding: 0;
    margin: 0;
    margin-bottom: 4.5rem;
    margin-left: 5.5rem;
    margin-right: 5.5rem;
    max-width: 1224px;
  }

  .title {
    font-size: 4.5rem;
    line-height: 5.5rem;
  }

  .fullScreen .title {
    font-size: 3rem;
    line-height: 4rem;
    white-space: normal;
  }

  .subTitle {
    font-size: 1.75rem;
    line-height: 2.625rem;
    width: 80%;
  }

  .mobile {
    display: none;
  }

  .desktop {
    display: block;
  }
}

@media screen and (min-width: 1920px) {
  .container {
    height: 1000px;
  }
  .content {
    margin-bottom: 7.25rem;
    margin-left: 19.25rem;
    margin-right: 19.25rem;
  }

  .title {
    font-size: 4.5rem;
    line-height: 5.5rem;
    width: 90%;
  }
  
  .fullScreen .title {
    font-size: 3rem;
    line-height: 4rem;
  }

  .subTitle {
    font-size: 1.75rem;
    line-height: 2.625rem;
    width: 65%;
  }
}
