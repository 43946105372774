@value colors: 'styles/colors.module.css';
@value SHADES_BLACK, WHITE, NEUTRAL_GRAY_500 from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value NEW_TABLET_LANDSCAPE_MAX, NEW_MOBILE_MAX from breakpoints;

.container {
    display: flex;
    min-height: 565px;
    height: calc(((100vw - 2rem) / 9) * 4);
    width: 100%;
    position: relative;
}

.backgroundImage {
    display: block;
    position: relative;
    width: 100vw;
    left: 0;
    bottom: 0;
    object-fit: cover;
    z-index: -1;
    overflow: hidden;
}

.containerNoImage {
    background: SHADES_BLACK;
}

.overlay {
    position: absolute;
    height: calc(((100vw - 2rem) / 9) * 4);
    width: 100vw;
    background: SHADES_BLACK;
    opacity: 60%;
}

.content {
    padding: 10rem 10rem 6.25rem;
    color: WHITE;
    width: 100%;
    align-self: flex-end;
    position: absolute;
    bottom: 0;
}

.titleContainer {
    align-self: center;
}

.title {
    font-size: 3.5rem;
    line-height: 3.75rem;
    color: WHITE;
    font-weight: 500;
}

.subTitle {
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: WHITE;
    margin-bottom: 0.5rem;
    padding-top: 1.5rem;
}

.ctaContainer {
    padding-top: 2.5rem;
}

.secondLine {
    display: flex;
    flex-grow: 3;
    justify-content: space-between;
    padding-top: 7.125rem;
}

.secondLineHeader {
    line-height: 1.75rem;
    font-size: 1.125rem;
    font-weight: 400;
}

.secondLineHeader svg {
    padding-right: 0.75rem;
}

.secondLineHeader span {
    display: inline-flex;
    align-items: center;
}

.secondLineSubHeader {
    padding-top: 0.25rem;
    line-height: 1.5rem;
    font-size: 1rem;
    letter-spacing: 0.1px;
    font-weight: 400;
}

@media screen and (max-width: NEW_TABLET_LANDSCAPE_MAX) {
    .container {
        display: block;
        height: 40rem;
    }

    .overlay {
        height: 100%;
    }

    .backgroundImage {
        position: absolute;
        height: 100%;
        width: 100%;
    }

    .content {
        padding: 5rem;
    }

    .secondLine {
        padding-top: 5rem;
    }

    .subTitle {
        line-height: 2rem;
    }
}

@media screen and (max-width: NEW_MOBILE_MAX) {
    .container {
        height: 45rem;
    }

    .content {
        padding: 2rem;
    }

    .backgroundImage {
        position: absolute;
        height: 100%;
        width: 100%;
    }

    .secondLine {
        display: block;
        padding-top: 1.5rem;
    }

    .title {
        font-size: 2.75rem;
        line-height: 3rem;
        font-weight: 700;
    }

    .subTitle {
        font-size: 1rem;
        line-height: 1.5rem;
        margin-bottom: 0;
    }

    .secondLineSubHeader {
        padding-top: 0;
        padding-bottom: 1.25rem;
    }

    .ctaContainer {
        padding-top: 1.25rem;
    }

    .cta {
        padding: 1rem;
        width: 100%;
    }
}
