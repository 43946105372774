@value colors: 'styles/colors.module.css';
@value SHADES_BLACK, WHITE, NEUTRAL_GRAY_100, NEUTRAL_GRAY_50, NEUTRAL_GRAY_500, NEUTRAL_GRAY_200, NEUTRAL_GRAY_300 from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value NEW_MOBILE_MAX, NEW_TABLET_PORTRAIT_MAX from breakpoints;

.container {
  display: flex;
  flex-direction: column;
  background-color: NEUTRAL_GRAY_50;
  padding: 4rem 2rem 3.625rem;
}

.ctaContainer {
  padding-top: 2.5rem;
  display: flex;
  justify-content: center;
}

.label {
  text-align: center;
  color: NEUTRAL_GRAY_500;
  font-size: 0.875rem;
  line-height: 1rem;
  padding-bottom: 1rem;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  align-self: center;
}

.headerContainer {
  display: flex;
  width: 100%;
}

.dash {
  display: flex;
  width: 100%;
  border-top: 1px solid NEUTRAL_GRAY_300;
  margin-top: 1rem;
}

h2.header {
  width: 100%;
  color: black;
  font-size: 2.125rem;
  font-weight: 700;
  line-height: 2.25rem;
  text-align: center;
  margin-bottom: 2.5rem;
  white-space: nowrap;
  margin-left: 1rem;
  margin-right: 1rem;
}

.tableLine {
  border: 2px solid SHADES_BLACK;
}

.container table {
  table-layout: fixed;
  width: 100%;
}

.container table tr {
  border-bottom: 1px solid #e5e5e5;
}

.container table th:first-child {
  width: 40%;
}

.container table th {
  width: 20%;
  max-width: 20%;
  vertical-align: bottom;
}

.container table td:first-child,
.container table th:first-child {
  width: 40%;
  text-align: left;
  max-width: 40%;
  padding-left: 1rem;
}

.container table td {
  width: 20%;
  text-align: center;
  vertical-align: middle;
  max-width: 20%;
  padding: 0.75rem 0;
  border-right: 1px solid WHITE;
}

.container table th {
  background-color: NEUTRAL_GRAY_50;
  padding: 2rem 1rem 1rem;
  font-weight: bold;
}

.container table tr:nth-child(2n+1) {
  background-color: NEUTRAL_GRAY_200;
}

@media screen and (max-width: NEW_TABLET_PORTRAIT_MAX) {
  .container {
    height: auto;
    padding: 4rem 3rem 3rem;
  }

}

@media screen and (max-width: NEW_MOBILE_MAX) {
  .container {
    padding: 2rem 0.25rem;
  }

  h2.header {
    white-space: normal;
    margin: 0 2.5rem 2rem;
  }

  .dash {
    display: none;
  }

  .ctaContainer {
    padding: 2rem 1rem 1rem;
  }

  .cta {
    width: 100%;
  }

  .container table td:first-child,
  .container table th:first-child {
    width: 20%;
  }
}
