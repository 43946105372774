@value colors: 'styles/colors.module.css';
@value CHARCOAL from colors;

.detailItem {
  display: flex;
  margin-bottom: 0.5em;
  font-size: 1em;
  color: CHARCOAL;
}

.checkIcon {
  flex-shrink: 0;
  width: 1.25em;
  height: 1.25em;
  margin-top: 0.1em;
  margin-right: 0.5em;
}

.bold {
  font-weight: 500;
}
