@value colors: 'styles/colors.module.css';
@value PURPLE, WHITE, NEUTRAL_GRAY_700, NEUTRAL_GRAY_300 from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.container {
  padding: 4rem 1rem;
  background-color: NEUTRAL_GRAY_700;
}

.content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.internalLinks {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  width: 50%;
}

.internalLink {
  text-decoration: none;
  color: WHITE;
  margin-bottom: 1rem;
  font-weight: 500;
}

.thridCol {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.socalLinks {
  margin-top: 1rem;
}

.socialLink {
  margin-right: 1.5rem;
}

.termsAndCopyright {
  margin-top: 2.5rem;
  color: NEUTRAL_GRAY_300;
}

.termsAndCopyright a {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: NEUTRAL_GRAY_300;
  margin-top: 1rem;
}

.border {
  display: none;
}

.bullet {
  display: none;
}

@media screen and (min-width: TABLET_WIDTH) {
  .content {
    display: flex;
  }

  .internalLinks {
    width: 33%;
    flex: 1;
  }

  .border {
    display: block;
    width: 80px;
    height: 2px;
    background-image: linear-gradient(to right, #830051, #ff6d42);
    margin: 2.5rem 0;
  }

  .termsAndCopyright {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .termsAndCopyright a {
    margin-top: 0;
  }

  .footerLinks {
    display: flex;
    align-items: center;
  }

  .bullet {
    display: inline-block;
  }

  .footerLinks a:not(:last-child),
  .footerLinks span {
    margin-right: 1rem;
  }
}

@media screen and (min-width: DESKTOP_MED_WIDTH) {
  .container {
    padding: 4rem 6.75rem;
  }
}