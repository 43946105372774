@value colors: 'styles/colors.module.css';
@value PURPLE, WHITE, BLUE_GRAYS_01, BLUE_GRAYS_05, BLUE_GRAYS_SEMI_BLACK from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.programCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: white;
  border-radius: 0.875rem;
  padding: 1.5rem;
  gap: 1.5rem;
  width: 100%;
  box-shadow: 0rem 0.625rem 1.875rem 0rem rgba(0, 0, 0, 0.08);
}

.programCardHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.programOverline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  width: 100%;
}

.programOverline > span {
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.235625rem;
  background: linear-gradient(270deg, #E57054 0%, #85254E 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
}

.programCardStrikeThrough {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  color: BLUE_GRAYS_05;
  text-decoration: line-through;
  opacity: 0.4;
  margin-bottom: 0.5rem;
}

.programCardTitle {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
}

.programCardDailyPrice {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  background: linear-gradient(270deg, #E57054 0%, #85254E 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.programCardDescription {
  color: BLUE_GRAYS_05;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.programCardBody {
  width: 100%;
}

.programBaseProductHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1.25rem;
  padding-top: 1.5rem;
  border-top: 0.125rem solid BLUE_GRAYS_01;
  gap: 1rem;
}

.programBaseProductTitle {
  color: BLUE_GRAYS_SEMI_BLACK;
  font-size: 1rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.programBaseProductStrikeThrough {
  color: BLUE_GRAYS_05;
  font-size: 1rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-decoration: line-through;
  opacity: 0.4;
  margin-right: 0.5rem;
}

.programBaseProductSubTitle {
  color: BLUE_GRAYS_05;
  font-size: 1rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.programDetailItem {
  display: flex;
  justify-content: flex-start;
  gap: 0.625rem;
  margin-bottom: 0.5rem;
}

.programCardCheckCircle {
  display: flex;
  width: 0.875rem;
  height: 0.875rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5625rem;
  border-radius: 1.9375rem;
  background: BLUE_GRAYS_01;
  margin-top: 0.0625rem;
}

.programCardDetailText {
  color: BLUE_GRAYS_05;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  margin-top: 0.0625rem;
}

.programCardFreeTrialContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.625rem;
}

.programCardFreeTrialText {
  color: BLUE_GRAYS_05;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media screen and (min-width: DESKTOP_WIDTH) {
  .programCard {
    padding: 1.875rem;
  }

  .minHeight {
    min-height: 398px;
  }
}