@value colors: 'styles/colors.module.css';
@value PURPLE, WHITE from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.container {
  background-color: #f7f7f7;
  padding: 4rem 2rem;
}

.content {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
}

.header {
  color: black;
  font-size: 2rem;
  line-height: 2.25rem;
  text-align: center;
  font-weight: bold;
}

.items {
  display: flex;
  flex-direction: column;
}

.item {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
}

.subHeader {
  font-weight: 500;
  font-size: 1.5rem;
  text-align: center;
}

.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
  height: 200px;
}

.image {
  object-fit: cover;
  width: 100%;
}

.description {
  font-size: 1rem;
  text-align: center;
}

@media screen and (min-width: TABLET_WIDTH) {
  .imageWrapper {
    object-fit: contain;
    height: 325px;
  }

  .image {
    width: 75%;
  }
}

@media screen and (min-width: DESKTOP_WIDTH) {
  .items {
    flex-direction: row;
    justify-content: space-evenly;
  }

  .item {
    width: 33%;
    margin: 0 1rem;
  }

  .imageWrapper {
    height: 200px;
  }

  .image {
    width: 100%;
  }
}
