@value colors: 'styles/colors.module.css';
@value PURPLE, WHITE, BLACK from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH, NEW_MOBILE_MAX from breakpoints;

.container {
  display: flex;
  min-height: 45rem;
  width: 100%;
  position: relative;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  color: WHITE;
  justify-content: flex-end;
  margin: 0 1rem;
  padding: 1rem;
  width: 100%;
  max-width: 85rem;
}

.titleContainer {
  text-shadow: 0 0 30px BLACK;
}

.ratingsImage {
  border-radius: 0.625rem;
  width: 100%;
  max-width: 22rem;
  background: rgba(255, 255, 255, 0.14);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(10px);
  align-self: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.contentContainer {
  display: flex;
  flex-direction: column;

  width: 100%;
  justify-content: space-between;
}

.title {
  text-align: center;
  font-size: 2.375rem;
  font-weight: 800;
}

.subTitle {
  text-align: center;
  font-size: 1.125rem;
  font-weight: 600;
  color: WHITE;
  margin: 1rem 0;
}

@media screen and (min-width: TABLET_WIDTH) {
  .container {
    min-height: 625px;
  }

  .title {
    text-align: start;
  }

  .subTitle {
    text-align: start;
  }

  .contentContainer {
    align-items: flex-end;
    flex-direction: row;
  }

  .ratingsImage {
    margin: 0 1rem 2.5rem 0;
    width: 12rem;
  }
}

@media screen and (min-width: DESKTOP_WIDTH) {
  .container {
    min-height: 700px;
  }

  .titleContainer {
    max-width: 38rem;
  }

  .title {
    font-size: 3.75rem;
  }

  .subTitle {
    font-size: 1.875rem;
  }

  .ratingsImage {
    width: 16rem;
  }
}