@value breakpoints: 'styles/breakpoints.module.css';
@value TABLET_WIDTH from breakpoints;

.container {
  width: 100%;
  max-width: 70rem;
  margin: 0 auto;
  padding: 3.75rem 1.875rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.75rem;
}

.text {
  font-size: 1.375rem;
  font-weight: 800;
  text-align: center;
}

.image {
  height: 16rem;
  width: 16rem;
}

@media (min-width: TABLET_WIDTH) {
  .text {
    font-size: 3rem;
    line-height: 4.25rem;
    margin-right: 4rem;
    text-align: start;
  }

  .container {
    flex-direction: row-reverse;
  }

  .image {
    flex-shrink: 0;
    height: 13rem;
    width: 13rem;
    margin-left: 4rem;
  }
}
