.container {
  position: relative;
}

.img {
  width: 100%;
  object-fit: cover;
  height: 425px;
  max-height: 100vw;
}

@media only screen and (min-width: 992px) {
  .img {
    border-radius: 0.25rem;
    max-width: 425px;
  }
}
