@value colors: 'styles/colors.module.css';
@value PURPLE, WHITE from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.container {
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  background: rgba(255, 255, 255, 0.40);
  backdrop-filter: blur(10px);
  z-index: 10;
  padding: 1.125rem 1.875rem;
  filter: drop-shadow(0px -1px 0px #e2e8f0);
  transition: transform 0.3s;
  transform: translateY(0);
}

.hide {
  /* display: none; */
  transform: translateY(100%);
}

.show {

  /* display: flex; */
}

button.button {
  width: 100%;
  font-weight: 800;
  border-radius: 0.625rem;
}

@media screen and (min-width: TABLET_WIDTH) {
  .button.button {
    width: unset;
  }
}

@media screen and (min-width: DESKTOP_WIDTH) {
  .container {
    display: none;
  }
}
