@value colors: 'styles/colors.module.css';
@value NEUTRAL_GRAY_50, BLACK, GRADIENT_PRIMARY_DEFAULT from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value NEW_TABLET_PORTRAIT_MAX, NEW_MOBILE_MAX from breakpoints;

.root {
    width: 100%;
    background-color: NEUTRAL_GRAY_50;
}

.content {
    padding: 1.25rem 10rem;
}

.cityColor {
    background: GRADIENT_PRIMARY_DEFAULT;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.chevron {
    margin-right: 1.375rem;
    margin-left: 1.375rem;
}

.link {
    color: BLACK;
    z-index: 1;
}

@media screen and (max-width: NEW_TABLET_PORTRAIT_MAX) {
    .cityColor, .content {
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 400;
    }

    .content {
        padding: 0.688rem 6rem;
    }
}

@media screen and (max-width: NEW_MOBILE_MAX) {
    .root {
        display: none;
    }
}

