@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_WIDTH from breakpoints;

.col {
  width: 100%;
  margin-bottom: 32px;
}

@media screen and (min-width: DESKTOP_WIDTH) {
  .col {
    width: 50%;
  }

  .col:last-child {
    padding-left: 128px;
  }
}
