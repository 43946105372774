@value colors: 'styles/colors.module.css';
@value SILVER, CHARCOAL, ORANGE, PURPLE, ACCENT_GREY, WHITE from colors;

.product {
  padding: 1em 1.5em;
  border: solid 2px SILVER;
  background-color: SILVER;
  border-radius: 1em;
}

.product.productCurrent {
  border-color: ORANGE;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 0.5em;
}

.name {
  font-size: 1.25em;
  font-weight: 500;
  color: CHARCOAL;
}

.highlight {
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-weight: 500;
  color: ORANGE;
}

.details {
  margin-bottom: 0.5em;
  margin-left: 0.5em;
}

.detailItem {
  display: flex;
  margin-bottom: 0.5em;
  font-size: 1em;
  color: CHARCOAL;
}

.checkIcon {
  flex-shrink: 0;
  width: 1.25em;
  height: 1.25em;
  margin-top: 0.1em;
  margin-right: 0.5em;
}

.bold {
  font-weight: 500;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.perDay {
}

.perDayAmount {
  font-size: 2em;
  font-weight: 500;
  color: CHARCOAL;
}

.perDayLabel {
  font-size: 1em;
  font-size: 0.9em;
}

.monthlyPrice {
  font-size: 0.9em;
  margin-top: -0.25em;
  color: ACCENT_GREY;
}

.button {
  composes: baseV2 from 'styles/buttons.module.css';
  composes: mediumV2 from 'styles/buttons.module.css';
  composes: solidPurple from 'styles/buttons.module.css';
  position: relative;
  margin-left: 0.2rem;
  flex-shrink: 0;
}

.spinnerWrapper {
  composes: center from 'styles/position.module.css';
}

.spinner {
  color: WHITE !important;
}

.invisible {
  visibility: hidden;
}
