@value colors: 'styles/colors.module.css';
@value PURPLE, WHITE from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.container {
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  background-color: WHITE;
  z-index: 10;
  padding: 1rem;
  filter: drop-shadow(0px -1px 0px #e2e8f0);
}

.hide {
  display: none;
}

.show {
  display: flex;
}

button.button {
  width: 100%;
}

@media screen and (min-width: TABLET_WIDTH) {
  .button.button {
    width: unset;
  }
}

@media screen and (min-width: DESKTOP_WIDTH) {
  .container {
    display: none;
  }
}
