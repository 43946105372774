@value colors: 'styles/colors.module.css';
@value PURPLE, WHITE, BLACK, LIGHT_TEXT_GREY, SILVER from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value MOBILE_WIDTH_MAX, TABLET_WIDTH, MOBILE_WIDTH from breakpoints;

.howItWorksSection {
  background-color: #efefef;
  padding: 5.5rem;
  overflow: hidden;
}

.headers {
  text-align: center;
  margin-bottom: 5rem;
}

.header {
  composes: subHeader from 'styles/homepage.module.css';
  color: LIGHT_TEXT_GREY;
  margin-bottom: 1.75rem;
}

.subHeader {
  composes: header from 'styles/homepage.module.css';
  color: BLACK;
}

.sections {
  max-width: 1100px;
  margin: 0 auto;
}

.section {
  display: flex;
  justify-content: space-between;
}

.section:not(:last-child) {
  margin-bottom: 3rem;
}

.sectionMain {
  position: relative;
  width: 42%;
}

.sectionHeaderWrapper {
  display: flex;
  align-items: center;
  padding: 4.2rem 0 2rem 0;
}

.sectionHeaderIdx {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-50%);
  color: rgba(40, 40, 40, 0.08);
  font-size: 8rem;
  font-weight: 900;
  line-height: 1;
}

.sectionHeader {
  color: BLACK;
  font-size: 2.25rem;
  line-height: 1.25;
}

.sectionBaseText {
  font-size: 1.375rem;
}

.sectionSubHeader {
  composes: sectionBaseText;
  font-weight: 500;
  margin-bottom: 1rem;
}

.textSection {
  max-width: 368px;
}

.sectionText {
  composes: sectionBaseText;
  composes: textSection;
  white-space: pre-wrap;
}

.detailWrapper {
  composes: textSection;
}

.sectionImages {
  flex: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26rem;
}

.sectionImage {
  max-width: 100%;
  max-height: 100%;
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .howItWorksSection {
    padding: 3rem 1.5rem;
  }

  .subHeader {
    font-size: 3rem;
  }

  .sections {
    padding: 0 1.5rem;
  }

  .section {
    display: block;
  }

  .sectionMain {
    width: 100%;
    margin: 0;
  }

  .headers {
    margin-bottom: 3rem;
  }

  .sectionImages {
    height: auto;
    margin-top: 3rem;
  }

  .sectionImage {
    width: 100%;
    max-width: 350px;
  }

  .sectionHeader {
    font-size: 2.25rem;
  }
}

@media screen and (max-width: MOBILE_WIDTH) {
  .headers {
    margin-bottom: 1rem;
  }

  .sections {
    padding: 0 1rem;
  }

  .sectionHeaderWrapper {
    padding-bottom: 1.5rem;
  }

  .sectionHeaderIdx {
    font-size: 7rem;
    line-height: normal;
  }

  .sectionHeader {
    font-size: 2rem;
  }

  .sectionText {
    font-size: 1.25rem;
  }
}
