@value GREEN_GRADIENT: linear-gradient(224deg, #5BD1AE 18.35%, #0A4 83.1%);
@value RED_GRADIENT: linear-gradient(244deg, #E56F53 4.31%, #85244D 93.59%);
@value LIGHT_RED_GRADIENT: linear-gradient(244deg, #FF3030 0.37%, #D80000 33.82%);

.redGradient {
    background: RED_GRADIENT;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.greenGradient {
    background: GREEN_GRADIENT;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.lightRedGradient {
    background: LIGHT_RED_GRADIENT;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}