@value colors: 'styles/colors.module.css';
@value PURPLE from colors;

.container {
  padding: 2rem;
}

.response {
  padding: 2rem 0;
}

.response:first-child {
  padding-top: 0;
}

.response:last-child {
  padding-bottom: 0;
}

.text {
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-top: 1.5rem;
  max-width: 600px;
}

@media only screen and (min-width: 992px) {
  .container {
    padding: 2rem 0;
  }

  .text {
    font-size: 1.25rem;
    line-height: 2rem;
    max-width: 768px;
  }
}
