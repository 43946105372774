@value colors: 'styles/colors.module.css';
@value PURPLE from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, MOBILE_WIDTH_MAX, TABLET_WIDTH from breakpoints;

.section {
  margin-bottom: 5rem !important;
}

.sectionMain {
  margin-top: 1rem;
}

.images {
  composes: sectionImages from '../styles.module.css';
}

.coachMatchesImage {
  composes: sectionImage from '../styles.module.css';
}

.sectionText {
  composes: sectionText from '../styles.module.css';
  max-width: 22rem;
}

.link {
  color: PURPLE;
  text-decoration: underline;
}

.detail {
  display: flex;
  align-items: center;
  max-width: 18rem;
  position: relative;
  margin-left: auto;
  margin-top: 3rem;
}

.detailText {
  font-size: 1.125rem;
  font-weight: 500;
  color: #d2910f;
}

.muscleEmoji {
  width: 2.5rem;
  flex-shrink: 0;
  margin-right: 0.8rem;
}

.detailArrow {
  position: absolute;
  top: 0;
  right: 1rem;
  transform: translate(100%, -100%);
  height: 8rem;
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .section {
    composes: section from '../styles.module.css';
    margin-bottom: 0 !important;
  }
}

@media screen and (max-width: TABLET_WIDTH) {
  /* .images {
    height: 25rem;
  } */
}
