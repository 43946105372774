@value colors: 'styles/colors.module.css';
@value WHITE, ACCENT_GREY from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_WIDTH, TABLET_WIDTH, MOBILE_WIDTH from breakpoints;

.container {
  display: flex;
  width: 100%;
  background-color: WHITE;
  padding: 1.5rem 0;
  overflow: hidden;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 2rem;
  animation: slide 30s linear infinite;
}

.logo {
  width: 100px;
  margin-bottom: 1rem;
  opacity: 50%;
}

@keyframes slide {
  from {
    transform: translateX(20vw);
  }
  to {
    transform: translateX(-100%);
  }
}

@media screen and (min-width: TABLET_WIDTH) {
  .container {
    justify-content: center;
    overflow-x: hidden;
  }

  .logo {
    width: 125px;
  }
}

@media screen and (min-width: DESKTOP_WIDTH) {
  .content {
    animation: unset;
    gap: 4rem;
    overflow-x: hidden;
  }
}