@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.container {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 4rem 2rem;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
}

h2.header {
  color: black;
  font-size: 2rem;
  line-height: 2.25rem;
  text-align: center;
  font-weight: bold;
  margin-bottom: 4rem;
}

.logo {
  width: 80px;
  margin-bottom: -0.15rem;
}

.container table {
  table-layout: fixed;
  width: 100%;
}

.container table tr {
  border-bottom: 1px solid #e5e5e5;
}

.container table th:first-child {
  width: 40%;
}

.container table th {
  width: 20%;
  max-width: 20%;
  vertical-align: bottom;
}

.container table td:first-child {
  width: 40%;
  text-align: left;
  font-weight: 500;
  max-width: 40%;
}

.container table td {
  width: 20%;
  text-align: center;
  vertical-align: middle;
  max-width: 20%;
  padding: 1rem 0;
}

.container table th {
  padding-bottom: 2rem;
}

.container table td strong,
.container table th strong {
  font-weight: bold;
}

.container table tr:last-child td:nth-child(2) {
  border: 3px solid #2cb572;
}

@media screen and (min-width: TABLET_WIDTH) {
  .logo {
    width: 100px;
  }
}
