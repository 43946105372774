@value colors: 'styles/colors.module.css';
@value NEUTRAL_GRAY_600 from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_WIDTH from breakpoints;

span.subtitle {
  display: block;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 8px;
  /* color: NEUTRAL_GRAY_400; */
  color: NEUTRAL_GRAY_600;
}

h2.title {
  font-size: 1.75rem;
  line-height: 2rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
}

.border {
  width: 80px;
  height: 2px;
  background-image: linear-gradient(to right, #830051, #ff6d42);
}

@media screen and (min-width: DESKTOP_WIDTH) {
  span.subtitle {
    font-size: 18px;
    line-height: 28px;
  }

  h3.title {
    font-size: 36px;
    line-height: 40px;
  }
}
