@value colors: 'styles/colors.module.css';
@value WHITE, CHARCOAL, PURPLE, LIGHTER_GREY, ORANGE from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value MOBILE_WIDTH_MAX from breakpoints;

.product {
  display: flex;
  flex-direction: column;
  background-color: WHITE;
  padding: 2rem;
  border-radius: 0.5rem;
  height: 32rem;
  box-shadow: 0px 55.5145px 90.0235px rgba(0, 0, 0, 0.06);
  border: solid 2px WHITE;
}

.productRecommended {
  border: solid 2px PURPLE;
}

.main {
  flex: 1;
}

.name {
  font-size: 1.5rem;
  font-weight: 500;
  color: CHARCOAL;
  margin-bottom: 0.75rem;
}

.dailyPrice {
  display: flex;
  align-items: baseline;
}

.dailyPriceValue {
  font-size: 2rem;
  font-weight: 500;
  color: CHARCOAL;
}

.dailyPriceLabel {
  color: LIGHTER_GREY;
  font-weight: 500;
  margin-left: 0.4rem;
}

.price {
  color: LIGHTER_GREY;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 1.25rem;
}

.freeTrialText {
  font-weight: 600;
  margin-left: 0.25rem;
}

.checkIcon {
  width: 1.1rem;
  height: auto;
  margin-right: 0.8rem;
}

.detailItem {
  margin-bottom: 0.7rem;
}

.detailText {
  font-weight: 500;
}

.detailTextBold {
  color: PURPLE;
}

.callFrequencySelect {
  margin-bottom: 0.5rem;
}

.submitButton {
  composes: baseV2 from 'styles/buttons.module.css';
  composes: mediumV2 from 'styles/buttons.module.css';
  composes: hollowPurple from 'styles/buttons.module.css';
  font-size: 1rem;
  font-weight: 600;
}

.submitButtonRecommended {
  background-color: PURPLE;
  color: WHITE;
}

.currentPlanNote {
  color: ORANGE;
  font-weight: 500;
  text-align: center;
}

.spinner {
  font-size: 1.1rem !important;
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .product {
    height: auto;
  }

  .submitButton {
    margin-top: 2rem;
  }
}