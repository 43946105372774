@value colors: 'styles/colors.module.css';
@value PURPLE, WHITE, SHADES_BLACK, NEUTRAL_GRAY_400, ERROR_500 from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.formWrapper {
  display: flex;
  flex-direction: column;
  background-color: WHITE;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.11); */
  /* border-radius: 0.5rem; */
  padding: 2.5rem 1rem;
  width: 100%;
}

.header {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
  color: SHADES_BLACK;
  margin-bottom: 0.5rem;
  width: 100%;
  text-align: center;
  margin-top: 0.5rem;
}

.subHeader {
  font-size: 1rem;
  line-height: 1.5rem;
  color: NEUTRAL_GRAY_400;
  text-transform: uppercase;
  text-align: center;
}

.coachImage {
  display: flex;
  justify-content: center;
  align-self: center;
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 9999px;
  overflow: hidden;
  border: 2px solid WHITE;
  margin-top: 2rem;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
  align-items: center;
}

.row {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.row>div {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
}

.row>div:last-child {
  margin-bottom: 2.25rem;
}

.row>.headline {
  margin-bottom: 0;
}

.row>.headline:last-child {
  margin-bottom: 0;
}

.row>.buttonWrapper,
.row>.buttonWrapper:last-child {
  margin-bottom: 0;
}

.label {
  font-size: 1rem;
  line-height: 1.5rem;
  color: SHADES_BLACK;
  font-weight: 600;
  display: flex;
  margin-bottom: 1rem;
}

.input {
  margin: 0;
  margin-bottom: 2rem;
}

.input>label>div {
  margin: 0;
  width: 100%;
}

button.submitButton {
  text-transform: capitalize;
  width: 100%;
}

.error {
  color: ERROR_500;
  margin-top: 0.5rem;
}

.message {
  color: SUCCESS_500;
  font-size: 1.1rem;
  margin-top: 0.5rem;
}

@media screen and (min-width: TABLET_WIDTH) {
  .formWrapper {
    padding: 4rem 1rem;
  }

  .header {
    width: 400px;
  }

  /* .subHeader {
    width: 400px;
  } */

  .label {
    width: 400px;
  }

  .row>div {
    width: 400px;
    margin: 0 auto;
    align-items: flex-start;
    margin-bottom: 2rem;
  }

  .coachImage {
    width: 100px;
    height: 100px;
  }

  .input {
    width: 400px;
  }

  button.submitButton {
    width: 400px;
  }
}

@media screen and (min-width: DESKTOP_WIDTH) {
  .formWrapper {
    width: 1016px;
  }

  .header {
    font-size: 4.5rem;
    line-height: 1;
    margin-top: 1rem;
    width: 100%;
  }

  .coachImage {
    width: 185px;
    height: 185px;
  }

  .row {
    flex-direction: row;
  }

  .row>.buttonWrapper {
    margin-bottom: 0;
  }

  .form {
    margin-top: 4rem;
  }
}