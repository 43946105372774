@value colors: '~styles/colors.module.css';
@value breakpoints: 'styles/breakpoints.module.css';

@value NEUTRAL_GRAY_100, NEUTRAL_GRAY_600 from colors;
@value NEW_TABLET_LANDSCAPE from breakpoints;

.howItWorksSection {
  background-color: NEUTRAL_GRAY_100;
  padding: 2rem;
}

.howItWorksSection * {
  font-family: 'Lato', sans-serif;
}

.header {
  composes: large from 'components/design-system/type-system/title/styles.module.css';
  font-weight: 500;
  text-align: center;
  margin-bottom: 1rem;
  /* padding-top: 3rem; */
}

.sections {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.sections > * {
  margin-bottom: 1rem;
}

.card {
  text-align: center;
  padding: 1rem;
}

.cardTitle {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1rem;
}

.cardText {
  composes: medium from 'components/design-system/type-system/body/styles.module.css';
  color: NEUTRAL_GRAY_600;
}

.illustrationContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.illustrationContainer img {
  height: 125px;
  max-width: 100%;
}

@media screen and (min-width: NEW_TABLET_LANDSCAPE) {
  .howItWorksSection {
    padding: 6rem 3rem 8rem 3rem;
  }

  .header {
    margin-bottom: 3rem;
  }

  .card {
    padding: 2rem;
  }

  .cardTitle {
    font-size: 1.6785rem;
    line-height: 2.5rem;
  }

  .illustrationContainer img {
    height: 150px;
  }

  .sections {
    flex-direction: row;
    justify-content: space-between;
  }

  .sections > *:not(:last-child) {
    margin-right: 1rem;
  }
}
