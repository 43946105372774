@value colors: 'styles/colors.module.css';
@value WHITE, SHADES_BLACK, NEUTRAL_GRAY_100, NEUTRAL_GRAY_200, NEUTRAL_GRAY_500, PRIMARY_50, PRIMARY_900, BLUE_GRAYS_01, BLUE_GRAYS_05, BLUE_GRAYS_SEMI_BLACK from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;


.liveWorkoutSelectionContainer {
  margin-top: 1.875rem;
  display: flex;
  flex: 1;
  padding: 1.875rem;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 1rem;
  background: WHITE;
  box-shadow: 0rem 0.625rem 2.5rem 0rem rgba(0, 0, 0, 0.06);
}

.title {
  color: BLUE_GRAYS_SEMI_BLACK;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.p {
  margin-top: 0.5rem;
  color: BLUE_GRAYS_05;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.selections {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 100%;
}

.item {
  margin: 0.25rem 0;
}

.item:first-child {
  margin-top: 0;
}

.item:last-child {
  margin-bottom: 0;
}

.button {
  display: flex;
  justify-content: space-between;
  background-color: WHITE;
  border: 0.125rem solid BLUE_GRAYS_01;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  width: 100%;
}

.button:hover {
  background-color: NEUTRAL_GRAY_100;
}

.button.selected {
  color: PRIMARY_900;
  border: 0.125rem solid #E57054;
  background: #e5715438;
}

.button span {
  display: block;
  text-align: left;
  color: BLUE_GRAYS_SEMI_BLACK;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.button > span:last-child {
  text-align: right;
}

.button .description {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: NEUTRAL_GRAY_500;
}

.button.selected .description {
  background-color: PRIMARY_50;
  color: PRIMARY_900;
}

@media screen and (min-width: DESKTOP_WIDTH) {
  .p {
    font-size: 0.875rem;
  }

  .button span {
    font-size: 0.875rem;
  }
}