@value breakpoints: 'styles/breakpoints.module.css';
@value TABLET_WIDTH, DESKTOP_MED_WIDTH from breakpoints;

.container {
  margin: 2.5rem 1rem;
  padding-bottom: 0;
}

.container::before {
  content: ' ';
  display: block;
  height: 76px;
  margin-top: -76px;
  visibility: hidden;
}

.row {
  display: flex;
  width: 100%;
}

.wrap {
  compose: row;
  flex-wrap: wrap;
}

.wrapReverse {
  compose: row;
  flex-wrap: wrap-reverse;
}

@media screen and (min-width: TABLET_WIDTH) {
  .container {
    margin: 0 auto;
    padding: 0;
  }

  .row {
    padding: 3.5rem 1.5rem;
    align-items: center;
  }
}

@media screen and (min-width: DESKTOP_MED_WIDTH) {
  .container {
    max-width: 1440px;
    margin: 0 auto;
  }

  .row {
    padding: 3.5rem 6.75rem;
    align-items: center;
  }
}
