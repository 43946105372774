@value colors: 'styles/colors.module.css';
@value PURPLE, WHITE from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 2rem;
  background-color: PURPLE;
  color: WHITE;
}

h2.title {
  color: WHITE;
  font-size: 2rem;
  line-height: 2.25rem;
  text-align: center;
}

.bold {
  display: block;
  font-weight: 700;
}

.primaryItems {
  margin-top: 2rem;
}

@media screen and (min-width: TABLET_WIDTH) {
  .bold {
    display: inline;
    margin-left: 1rem;
  }
}
