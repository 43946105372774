@value colors: 'styles/colors.module.css';
@value BORDER_GREY, NEUTRAL_GRAY_50, NEUTRAL_GRAY_300 from colors;

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8rem 1rem 12rem;
  width: 100%;
  background: NEUTRAL_GRAY_50;
}

.content {
  max-width: 37.5rem;
  width: 100%;
}

.title {
  font-size: 2rem;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
}

.faqs {
  margin-top: 2rem;
}

.faq {
  border-bottom: solid 1px NEUTRAL_GRAY_300;
}
