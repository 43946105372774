.videoWrapper {
  position: relative;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 25%;
}

.video:fullscreen {
  object-fit: contain;
}

.playbutton {
  color: white;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.videoPlaceholder {
  width: 100%;
  height: 425px;
  background-color: lightgray;
}

@media only screen and (min-width: 992px) {
  .container {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .leftCol {
    display: flex;
    width: 50%;
    margin-top: 4rem;
    justify-content: flex-start;
    padding: 0 2rem;
  }

  .rightCol {
    padding: 0 2rem;
  }

  .videoWrapper {
    height: 425px;
    width: 425px;
  }

  .rightCol {
    display: flex;
    width: 50%;
    margin-top: 4rem;
    justify-content: flex-end;
  }
}
