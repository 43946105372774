.question {
  border: none;
  background-color: #EEF3F9;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0.625rem;
  padding: 0.75rem 1rem;
  appearance: none;
  outline: none;
  user-select: none;
  color: SHADES_BLACK;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
  font-weight: 800;
  text-align: start;
  color: #030017;
}

.questionRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.answer {
  padding-top: 1rem;
  font-weight: 600;
  text-align: start;
  color: #030017;
}