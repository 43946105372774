@value colors: 'styles/colors.module.css';
@value NEUTRAL_GRAY_100, NEUTRAL_GRAY_600, NEUTRAL_GRAY_900, SILVER, PURPLE, WHITE from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value MOBILE_WIDTH_MAX, TABLET_WIDTH from breakpoints;

.pricingSection {
  display: flex;
  background-color: WHITE;
}

.imageWrapper {
  flex: 2.5;
}

.image {
  width: 100%;
  height: 100%;
}

.main {
  width: 50%;
  padding: 4.75rem 3.125rem;
  flex: 1 0 auto;
}

.header {
  composes: header from 'styles/homepage.module.css';
  margin-bottom: 2.75rem;
  color: NEUTRAL_GRAY_900;
}

.subHeader {
  font-size: 2.375rem;
  margin-bottom: 4rem;
  color: NEUTRAL_GRAY_600;
}

.points {
  max-width: 35rem;
  margin-bottom: 6.875rem;
}

.point {
  display: flex;
}

.point:not(:last-child) {
  margin-bottom: 2.1875rem;
}

.checkIcon {
  width: 1.625rem;
  margin-right: 1rem;
  height: max-content;
  margin-top: 0.1rem;
  flex-shrink: 0;
}

.pointText {
  color: NEUTRAL_GRAY_900;
  font-size: 1.125rem;
}

.pointTextPrimary {
  composes: pointText;
  font-weight: 500;
}

.pointTextSecondary {
  composes: pointText;
}

.getStartedLink {
  composes: unstyled from 'styles/links.module.css';
}

.getStartedButton {
  composes: root primary from 'components/design-system/button/styles.module.css';
  border: 1px solid rgba(0, 0, 0, 0) !important;
  border-radius: 0.25rem !important;
  display: inline-block !important;
  font-size: 1.125rem !important;
  font-weight: 500 !important;
  line-height: 1.75rem !important;
  padding: 0.625rem !important;
  width: 12.75rem;
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .pricingSection {
    display: block;
  }

  .imageWrapper {
    display: none;
  }

  .main {
    width: 100%;
    padding: 3rem 1.5rem;
  }

  .header {
    line-height: 4.4rem;
    margin-bottom: 2rem;
  }

  .subHeader {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .points {
    margin-bottom: 4rem;
  }
}
