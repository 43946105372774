@value colors: 'styles/colors.module.css';
/* @value WHITE, ACCENT_GREY from colors; */
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.container {
  padding: 4rem 2rem;
  background-color: #f7f7f7;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 2rem;
  width: 100%;
  margin-bottom: 4rem;
}

.logos {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.logo {
  max-width: 140px;
  margin-bottom: 4rem;
}

.logo:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: DESKTOP_WIDTH) {
  .content {
    flex-direction: row;
  }

  .header {
    width: 25%;
    margin-bottom: 0;
  }

  .logos {
    flex-direction: row;
    width: 75%;
  }

  .logo {
    margin-bottom: 0;
    margin-right: 2rem;
  }

  .logo:last-child {
    margin-right: 0;
  }
}
