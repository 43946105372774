@value colors: 'styles/colors.module.css';
@value SILVER, WHITE, PURPLE, SUB_TEXT_GREY, CHARCOAL from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, MOBILE_WIDTH_MAX, TABLET_WIDTH from breakpoints;

.contentWrapper {
  min-height: 100vh;
  background-color: SILVER;
}

.contentWrapperPost {
  background-color: WHITE;
}

.content {
  padding: 3rem 0 1.5rem;
  font-size: 21px;
  position: relative;
}

.hero {
  height: 40vh;
  z-index: 1;
}

.article {
  max-width: 720px;
  margin: 0 auto;
  padding: 0 1rem;
}

.title {
  font-weight: 500;
  font-size: 1.875em;
  margin-bottom: 0.5em;
}

.postsWrapper {
  max-width: 1248px;
  margin: 0 auto;
  padding: 0.5rem 1rem;
}

.posts {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -1rem;
}

.postWrapper {
  width: 33.333333%;
  padding: 0 1rem;
  margin-bottom: 2rem;
}

.subText {
  color: SUB_TEXT_GREY;
  font-size: 0.9em;
}

.by {
  composes: subText;
}

.date {
  composes: subText;
  display: block;
  margin-bottom: 1.5em;
}

.link {
  color: PURPLE;
}

.backLink {
  color: SUB_TEXT_GREY;
  text-decoration: none;
  position: absolute;
  top: 1.25rem;
  left: 3.125rem;
  font-size: 0.9em;
}

.backIcon {
  color: SUB_TEXT_GREY;
  font-size: 0.9em;
  margin-right: 0.4em;
}

@media screen and (max-width: DESKTOP_LG_WIDTH) {
  .content {
    font-size: 20px;
  }
}

@media screen and (max-width: DESKTOP_MED_WIDTH) {
  .content {
    font-size: 19px;
  }

  .postWrapper {
    width: 50%;
  }
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .contentPost {
    padding-top: 4rem;
  }

  .backLink {
    left: 1.5rem;
  }
}

@media screen and (max-width: TABLET_WIDTH) {
  .content {
    font-size: 18px;
  }

  .postWrapper {
    width: 100%;
  }

  .backLink {
    left: 1rem;
  }
}
