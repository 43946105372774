@value colors: 'styles/colors.module.css';
@value PURPLE, WHITE, NEUTRAL_GRAY_100 from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.container {
  width: 100%;
  background: #F5F8FB;
  padding: 6.25rem 0;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  background-color: #FFF;
  max-width: 50rem;
  gap: 1.5rem;
  margin: 0 1rem;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
  border-radius: 0.625rem;
}

.logoContainer {
  display: flex;
}

.logo {
  height: 1rem;
}

.title {
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 800;
  margin-top: 0.5rem;
}

@media screen and (min-width: TABLET_WIDTH) {
  .title {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  .innerContainer {
    margin: 0 auto;

  }
}