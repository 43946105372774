@value colors: 'styles/colors.module.css';
@value WHITE, ORANGE, ACCENT_GREY, CHARCOAL from colors;

.form {
}

.goals {
  display: flex;
  height: 3rem;
}

.selectWrapper {
  flex: 1;
  height: 100%;
}

.selectText {
  font-size: 1.125rem;
  font-weight: 500;
  color: CHARCOAL;
  letter-spacing: 0.01em;
}

.select {
  composes: selectText;
  height: 100%;
  background-color: WHITE;
  border-radius: 1.875rem 0 0 1.875rem;
  padding-left: 1.125rem;
  padding-right: 1.125rem;
  border: 1px solid transparent !important;
}

.selectBordered {
  border: 1px solid ORANGE !important;
}

.selectPlaceholder {
  composes: selectText;
  left: 1.125rem;
}

.option {
  margin: 0;
}

.submit {
  composes: baseV2 from 'styles/buttons.module.css';
  composes: solidOrange from 'styles/buttons.module.css';
  height: 100%;
  flex-shrink: 0;
  border-radius: 0 1.875rem 1.875rem 0;
  font-size: 1.125rem;
  font-weight: 500;
  padding-left: 0.94rem;
  padding-right: 1.2rem;
  position: relative;
}

.submitDisabled {
  pointer-events: none;
}

.invisible {
  opacity: 0;
}

.spinnerWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  color: WHITE !important;
}

.error {
  margin-top: 0.5rem;
  color: ORANGE;
}

@media screen and (max-width: 330px) {
  .submit {
    font-size: 1rem;
  }

  .selectText {
    font-size: 1rem;
  }
}
