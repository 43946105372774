@value colors: 'styles/colors.module.css';
@value ORANGE, WHITE from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.container {
  display: flex;
  flex-direction: column;
  background-color: WHITE;
}

.imageContainer {
  padding: 1rem 0;
  background-color: #c4c4c4;
}

.bullets {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.bullet {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin: 0 0.5rem;
  border-radius: 50%;
  background-color: #e5e5e5;
}

.container :global(.swiper) {
  width: 100%;
}

.container :global(.swiper-pagination-bullet-active.swiper-pagination-bullet) {
  background-color: ORANGE;
  opacity: 1;
}

.container :global(.swiper-pagination-bullet) {
  background-color: #afafaf;
  opacity: 1;
  width: 15px;
  height: 15px;
}

.image {
  height: 100vw;
  width: 100%;
  object-fit: contain;
  max-width: 345px;
}

.quoteIcon {
  width: 2rem;
  margin-bottom: 2rem;
}

.testimonial {
  display: flex;
  flex-direction: column;
  background-color: WHITE;
  font-size: 1.25rem;
  padding: 3rem 2rem;
  min-height: 275px;
}

.name {
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.container :global(.prev) {
  display: none;
}

.container :global(.next) {
  display: none;
}

@media screen and (min-width: DESKTOP_WIDTH) {
  .container {
    flex-direction: row;
  }

  .container :global(.swiper-pagination) {
    display: none;
  }

  .container :global(.swiper-button-next) {
    top: unset;
    bottom: 3rem;
  }

  .imageContainer {
    width: 100%;
  }

  .afterImageWrapper {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  .image {
    height: 100%;
    width: auto;
  }

  .container :global(.prev) {
    display: block;
    position: absolute;
    bottom: 3rem;
    left: 3rem;
    z-index: 1;
    color: ORANGE;
  }

  .container :global(.prev.swiper-button-disabled) {
    opacity: 0.5;
  }

  .container :global(.next) {
    display: block;
    position: absolute;
    bottom: 3rem;
    right: 3rem;
    z-index: 1;
    color: ORANGE;
  }

  .container :global(.next.swiper-button-disabled) {
    opacity: 0.5;
  }
}
