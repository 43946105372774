@value colors: 'styles/colors.module.css';
@value PURPLE, WHITE, SHADES_BLACK, GRADIENT_PRIMARY_DEFAULT, DIAGONAL_GRADIENT from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.container {
  display: flex;
  position: sticky;
  top: 0;
  background: WHITE;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  padding: 1rem;
  z-index: 100;
  scroll-behavior: smooth;
}

.banner {
  top: 50px;
}

.shadow {
  box-shadow: 0px 1px 0px #e2e8f0;
}

.logoContainer {
  display: flex;
}

.logo {
  height: 1.25rem;
}

.mobileNavBtn {
  height: 20px;
}

.desktopMenu {
  display: none;
}

.mobileMenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  top: 3rem;
  bottom: 0;
  left: 0;
  right: 0;
  background: WHITE;
  z-index: 100;
  padding: 1rem;
  height: 100vh;
  transform: translate3d(-100vw, 0, 0);
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
}

.show {
  transform: translate3d(0vw, 0, 0);
}

.navItem {
  display: flex;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
  color: SHADES_BLACK;
  text-decoration: none;
  margin: 0.5rem 1rem;
}

.navItem:first-child {
  margin-top: 5rem;
}

.navItem:last-child {
  margin-top: 2rem;
}

.cta {
  margin-top: 1rem;
}

.navItem svg {
  margin-left: 0.5rem;
}

.login {
  padding: 0;
  margin: 0.5rem 1rem;
  text-align: left;
  justify-content: flex-start;
  border-radius: 0;
}

.login>span {
  background: GRADIENT_PRIMARY_DEFAULT;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: capitalize;
}

.login svg {
  stroke: url(#paint0_GRADIENT_PRIMARY_DEFAULT);
}

@media (min-width: TABLET_WIDTH) {
  .container {
    padding: 1.5rem;
  }
}

@media (min-width: DESKTOP_WIDTH) {
  .container {
    height: 5.875rem;
    padding: 2.25rem 1.5rem;
  }

  .logo {
    height: 1.75rem;
  }

  .mobileNavBtn {
    display: none;
  }

  .desktopMenu {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    top: unset;
    margin: 0;
    padding: 0;
    background: wHITE;
    width: auto;
  }

  .navItem {
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0;
    margin-right: 1rem;
  }

  .navItem:first-child {
    margin-top: unset;
  }

  .navItem:last-child {
    margin-top: unset;
  }

  .navItem:hover {
    box-shadow: inset 0px -1px 0px #212121;
  }
}

@media (min-width: DESKTOP_MED_WIDTH) {
  .container {
    padding: 2.25rem 1.5rem;
  }

  .navItem {
    margin: 0;
    margin-right: 2rem;
    font-size: 1.125rem;
  }

  .navItem:last-child {
    margin-left: 2.5rem;
  }

  .login {
    margin-left: 2.75rem;
    margin-right: 2.25rem;
  }
}

@media (min-width: 1440px) {
  .container {
    padding: 2.25rem 3rem;
  }
}

@media (min-width: 1920px) {
  .container {
    padding: 2.25rem 1.5rem;
  }
}