@value colors: 'styles/colors.module.css';
@value PURPLE from colors;

.container {
  display: flex;
  flex-direction: column;
}

.response {
  padding: 2rem;
}

.rightCol {
  display: none;
}

.text {
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-top: 1.5rem;
  max-width: 600px;
}

@media only screen and (min-width: 992px) {
  .container {
    flex-direction: row-reverse;
    margin-top: 3rem;
    align-items: center;
    justify-content: space-between;
  }

  .leftCol {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    padding: 0 2rem;
  }

  .rightCol {
    display: flex;
    width: 50%;
    justify-content: flex-start;
    padding: 0 2rem;
  }

  .rightColMobile {
    display: none;
  }

  .text {
    font-size: 1.25rem;
    line-height: 2rem;
    max-width: 768px;
  }
}
