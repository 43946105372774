@value colors: 'styles/colors.module.css';
@value SILVER, PURPLE from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value MOBILE_WIDTH_MAX, TABLET_WIDTH from breakpoints;

.accountabilitySection {
  display: flex;
  padding: 5.6785rem 3.125rem 10rem;
  background-color: PURPLE;
  height: 57rem;
}

.diagram {
  width: 60%;
  position: relative;
}

.textWrapper {
  flex: 1;
}

.header {
  composes: header from 'styles/homepage.module.css';
  line-height: 6rem;
  color: SILVER;
  margin-bottom: 5rem;
}

.subHeader {
  font-size: 1.5rem;
  font-weight: 500;
  color: SILVER;
}

.text {
  font-size: 1.5rem;
  color: SILVER;
  max-width: 26rem;
  white-space: pre-wrap;
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .accountabilitySection {
    flex-direction: column-reverse;
    padding: 3rem 1.5rem;
  }

  .header {
    line-height: 4rem;
    margin-bottom: 2.5rem;
  }

  .text {
    font-size: 1.15rem;
  }

  .diagram {
    width: 100%;
    height: 25rem;
  }
}
