@value colors: 'styles/colors.module.css';
@value GRADIENT_PRIMARY_DEFAULT from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 0rem;
}

.container::before {
  content: ' ';
  display: block;
  height: 42px;
  margin-top: -42px;
  visibility: hidden;
}

.sectionTitle {
  margin: 0 1rem;
}

.container p {
  margin: 0 1rem;
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.container p {
  margin-top: 1.5rem;
}

.trainers {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 5rem 0;
  width: 100%;
}

.trainer {
  width: 100%;
  margin: 0 1rem;
}

button.viewMoreButton {
  margin-top: 2rem;
}

.viewMoreButtonText {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  background: GRADIENT_PRIMARY_DEFAULT;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.viewMoreButtonIcon {
  stroke: url(#paint0_GRADIENT_PRIMARY_DEFAULT);
  margin-left: 0.5rem;
}

@media screen and (min-width: TABLET_WIDTH) {
  .trainer {
    width: unset;
    margin: unset;
  }

  .trainer:nth-child(odd) {
    margin-right: 1rem;
  }

  .mobileTrainers {
    display: none;
  }
}

@media screen and (min-width: DESKTOP_WIDTH) {
  .trainer:nth-child(odd) {
    margin-right: 0;
  }

  .trainer:not(:nth-child(3n)) {
    margin-right: 1rem;
  }
}

@media screen and (min-width: DESKTOP_MED_WIDTH) {
  .container::before {
    content: ' ';
    display: block;
    height: 76px;
    margin-top: -76px;
    visibility: hidden;
  }

  .trainer:nth-child(3n) {
    margin-right: 0;
  }

  .trainer:not(:nth-child(4n)) {
    margin-right: 1rem;
  }
}
