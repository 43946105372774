@value breakpoints: 'styles/breakpoints.module.css';
@value MOBILE_WIDTH_MAX from breakpoints;

.section {
  min-height: 440px !important;
}

.overlay {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.65) 0%,
    rgba(0, 0, 0, 0.3) 100%
  );
}

.main {
  padding: 3.125rem;
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .main {
    padding: 1.5rem 1.5rem 2rem;
  }
}
