@value colors: 'styles/colors.module.css';
@value SHADES_BLACK, WHITE, NEUTRAL_GRAY_50, LINK_BLUE from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value NEW_TABLET_PORTRAIT_MAX, NEW_MOBILE_MAX from breakpoints;

.container {
    display: flex;
    height: fit-content;
    width: 100%;
    position: relative;
    background: WHITE;
}

.content {
    display: flex;
    flex-direction: column;
    padding: 5rem 18rem;
    width: 100%;
}

.title {
    font-size: 3rem;
    line-height: 3.5rem;
    color: SHADES_BLACK;
    font-weight: 600;
    margin-bottom: 1rem;
}

.subTitle {
    color: SHADES_BLACK;
    font-size: 2.25rem;
    line-height: 2.9rem;
    margin-bottom: 2rem;
    font-weight: 400;
}

@media screen and (max-width: NEW_TABLET_PORTRAIT_MAX) {
    .container {
        height: auto;
    }

    .content {
        padding: 3.5rem;
    }

    .title, .subTitle {
        margin-bottom: 1.75rem;
    }
}

@media screen and (max-width: NEW_MOBILE_MAX) {
    .container {
        position: relative;
        display: block;
    }

    .content {
        position: relative;
        display: block;
        padding: 3rem 1.5rem;
    }

    .title {
        font-size: 2rem;
        line-height: 3rem;
    }

    .subTitle {
        font-size: 1.5rem;
        line-height: 2.5rem;
    }
}
