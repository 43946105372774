@value colors: 'styles/colors.module.css';
@value SILVER, ORANGE from colors;
@value ALT_PURPLE: #9c164e;
@value ANIMATION_DURATION: 0.6s;

@value breakpoints: 'styles/breakpoints.module.css';
@value MOBILE_WIDTH_MAX, TABLET_WIDTH from breakpoints;

.vennDiagram {
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  max-width: 45rem;
  margin-top: 44px;
  max-height: 120%;
}

.vennCircle {
  fill-opacity: 0;
}

.vennDiagramOpen .vennCircleActive {
  fill: ALT_PURPLE;
  fill-opacity: 1;
}

.vennCircle text {
  fill: SILVER;
  fill-opacity: 1;
  font-size: 13px;
  line-height: 16px;
}

.vennCircle path {
  stroke: ORANGE;
  stroke-width: 1;
}

.vennSetsABC {
  fill-opacity: 1;
  fill: ALT_PURPLE;
  stroke: ORANGE;
  stroke-width: 1;
  cursor: pointer;
}

.vennDiagramClosed .vennCircle {
  fill-opacity: 1;
  fill: ALT_PURPLE;
}

.vennDiagramClosed .vennSetsA {
  transform: translate(68px, -49px);
}

.vennDiagramClosed .vennSetsB {
  transform: translate(-68px, -49px);
}

.vennDiagramClosed .vennSetsC {
  transform: translate(0px, 69px);
}

.vennDiagramClosed .vennSetsABC {
  display: none;
}

.vennDiagramClosed text {
  opacity: 0;
}

.vennDiagramClosed .middleText {
  fill: SILVER;
  fill-opacity: 1;
  font-size: 10px;
  line-height: 16px;
  opacity: 1;
  animation: reverseMiddle ANIMATION_DURATION;
}

.vennDiagramClosed .vennSetsA {
  transform: translate(68px, -49px);
  animation: reverseA ANIMATION_DURATION;
}

.vennDiagramClosed .vennSetsB {
  transform: translate(-68px, -49px);
  animation: reverseB ANIMATION_DURATION;
}

.vennDiagramClosed .vennSetsC {
  transform: translate(0px, 69px);
  animation: reverseC ANIMATION_DURATION;
}

.vennDiagramOpen .vennSetsA {
  transform: translate(0, 0);
  animation: runA ANIMATION_DURATION;
}
.vennDiagramOpen .vennSetsB {
  transform: translate(0, 0);
  animation: runB ANIMATION_DURATION;
}
.vennDiagramOpen .vennSetsC {
  transform: translate(0, 0);
  animation: runC ANIMATION_DURATION;
}
.vennDiagramOpen .vennSetsABC {
  opacity: 1;
  animation: runABC ANIMATION_DURATION;
}

.vennDiagramOpen.vennDiagramBActive .vennSetsAB,
.vennDiagramOpen.vennDiagramCActive .vennSetsAC,
.vennDiagramOpen.vennDiagramCActive .vennSetsBC,
.vennDiagramOpen.vennDiagramAActive .vennSetsBC {
  stroke: ORANGE;
  stroke-width: 1;
}

.vennDiagramOpen .middleText {
  display: none;
}

@keyframes runA {
  0% {
    transform: translate(68px, -49px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes runB {
  0% {
    transform: translate(-68px, -49px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes runC {
  0% {
    transform: translate(0px, 69px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes runABC {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes reverseA {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(68px, -49px);
  }
}
@keyframes reverseB {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-68px, -49px);
  }
}
@keyframes reverseC {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0px, 69px);
  }
}

@keyframes reverseMiddle {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .vennDiagram {
    left: -13px;
    margin-left: -1.5rem;
    width: calc(100% + 3rem);
  }
}
