@value colors: 'styles/colors.module.css';
@value PURPLE, CHARCOAL from colors;

.faqPage a {
  color: PURPLE;
  text-decoration: none;
}

.container {
  display: flex;
  flex-direction: column;
  margin: 3rem;
  margin-top: 8rem;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.leftColumn h1 {
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.leftColumn p {
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: CHARCOAL;
  margin-top: 1.25rem;
}

.rightColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.grouping {
  font-size: 1rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  font-weight: bold;
  color: PURPLE;
  margin-bottom: 0.5rem;
  margin-top: 2.5rem;
}

.section {
  width: 100%;
  overflow: hidden;
}

.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 500;
  cursor: pointer;
}

.label svg {
  margin-left: 1rem;
}

.content {
  max-height: 0;
  padding: 0;
  color: CHARCOAL;
  font-size: 1rem;
  line-height: 1.5rem;
  white-space: pre-line;
}

.sectionActive .icon {
  transform: rotate(180deg);
}

.sectionActive > .content {
  max-height: 100vh;
}

@media screen and (min-width: 768px) {
  .container {
    flex-direction: row;
  }

  .leftColumn {
    width: 25%;
    margin-right: 2rem;
  }

  .rightColumn {
    width: 75%;
    margin-left: 2rem;
  }

  .grouping {
    margin-top: 0;
  }

  .grouping:not(:first-child) {
    margin-top: 2.5rem;
  }
}
