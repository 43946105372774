@value colors: 'styles/colors.module.css';
@value PURPLE, ORANGE, SILVER, CHARCOAL from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value MOBILE_WIDTH_MAX from breakpoints;

.footer {
  background-color: CHARCOAL;
  padding: 3.4375rem 3.125rem 6.25rem;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main {
  composes: row;
  margin-bottom: 1.0625rem;
}

.scrollButton {
  composes: unstyled from 'styles/buttons.module.css';
  text-decoration: underline;
  color: ORANGE;
  font-size: 1.5rem;
}

.below {
  composes: row;
}

.dba {
  display: flex;
  align-items: flex-end;
}

.dbaContent {
  font-size: 1.125rem;
  color: rgba(242, 242, 242, 0.8);
}

.dbaMobile {
  composes: dba;
  display: none;
}

.dbaText {
  color: rgba(242, 242, 242, 0.8);
}

.internalLinks {
}

.internalLink {
  composes: unstyled from 'styles/links.module.css';
  composes: hoverUnderline from 'styles/links.module.css';
  color: SILVER !important;
  font-size: 1.125rem !important;
  margin-right: 2.5rem;
}

.socialLinks {
  display: flex;
}

.socialLink {
  margin-right: 0.9375rem;
}

.socialIcon {
  z-index: 1;
  width: 2rem;
  height: 2rem;
}

.socialIconImage {
  object-fit: contain;
}

.bbbLogoWrapper {
  overflow: visible;
  width: 4rem;
  height: 1.5rem;
  margin-right: 4rem;
}

.bbbLogo {
  width: 100%;
  max-height: none;
  position: absolute;
  bottom: 0;
  border-radius: 6px;
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .footer {
    padding: 3rem 1.5rem;
  }

  .content {
    display: flex;
    justify-content: space-between;
  }

  .row {
    flex-direction: column;
  }

  .main {
    align-items: flex-start;
    margin-bottom: 2rem;
  }

  .internalLink {
    display: block;
    margin: 0;
    font-size: 1.25rem !important;
  }

  .internalLink:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  .socialLinks {
    flex-direction: column;
    margin-right: 0;
  }

  .socialLink:not(:last-child) {
    margin-bottom: 0.8rem;
  }

  .scrollButton {
    margin-top: 2rem;
    font-size: 1.25rem;
  }

  .dba {
    display: none;
  }

  .dbaMobile {
    display: flex;
  }

  .socialIcon {
    width: 2.3rem;
    height: 2.3rem;
  }

  .bbbLogoWrapper {
    margin-right: 0;
    margin-left: 1.5rem;
    width: 3.5rem;
  }
}

@media screen and (max-width: 380px) {
  .dbaMobile {
    max-width: 220px;
  }
}
