@value colors: 'styles/colors.module.css';
@value ORANGE, SILVER from colors;

@value WIDTH: 4.2rem;
@value HEIGHT: 0.65rem;

.container {
  display: flex;
  align-items: baseline;
}

.starsSection {
  position: relative;
  width: WIDTH;
  height: HEIGHT;
}

.starsWrapper {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.backgroundStars {
  composes: starsWrapper;
}

.ratingStars {
  composes: starsWrapper;
}

.stars {
  display: flex;
  justify-content: space-between;
  width: WIDTH;
}

svg.star {
  font-size: HEIGHT;
}

.backgroundStars .star {
  color: SILVER;
}

.ratingStars .star {
  color: ORANGE;
}

.ratingSection {
  margin-left: 0.65rem;
  font-size: 0.75rem;
  color: ORANGE;
}
