@value colors: 'styles/colors.module.css';
@value PURPLE from colors;

.container {
  display: flex;
  width: fit-content;
  height: 2.2rem;
  border-radius: 1.1rem;
  background-color: #f2e8ee;
}

.button {
  composes: unstyled from 'styles/buttons.module.css';
  width: 3rem !important;
  color: PURPLE !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.buttonDisabled {
  opacity: 0.4;
}

.icon {
  width: 0.7rem;
}

.value {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 2.2rem;
  background-color: PURPLE;
  color: WHITE;
  border-radius: 0.3rem;
}
