@value colors: 'styles/colors.module.css';
@value PURPLE from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value MOBILE_WIDTH_MAX, TABLET_WIDTH from breakpoints;

.workOut {
}

.images {
  composes: sectionImages from '../styles.module.css';
}

.textsImage {
  composes: sectionImage from '../styles.module.css';
  height: 100%;
}

.detail {
  display: flex;
  align-items: center;
  max-width: 10rem;
  position: relative;
  margin-left: auto;
  margin-top: 2rem;
  margin-right: 2rem;
}

.detailText {
  font-size: 1.125rem;
  font-weight: 500;
  color: #d2910f;
}

.loveEmoji {
  width: 2.5rem;
  flex-shrink: 0;
  margin-right: 0.8rem;
}

.detailArrow {
  position: absolute;
  top: 1rem;
  right: 0;
  transform: translate(100%, -100%);
  height: 8.2rem;
}

.getStartedLink {
  display: block;
  margin-top: 3rem;
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .workOut {
  }
}
