@value colors: 'styles/colors.module.css';
@value ORANGE, ACCENT_GREY, WHITE from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value TABLET_WIDTH, MOBILE_WIDTH_MAX from breakpoints;

.bio.container,
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 0;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
  gap: 1rem;
}

.bio .image,
.image {
  display: flex;
  object-fit: cover;
  object-position: center;
  image-orientation: from-image;
  width: 100%;
  max-width: 100%;
  height: auto;
}

.bio.rightCol,
.rightCol {
  width: 100%;
  background-color: WHITE;
  margin-top: -2rem;
  padding: 2rem 2rem 0;
  border-radius: 2.2rem 2.2rem 0 0;
  z-index: 1;
}

.bio.leftCol {
  width: 100%;
  padding: 0;
}

.bio.aboutMe,
.aboutMe {
  text-transform: uppercase;
  font-weight: 500;
  color: ORANGE;
}

.buttonWrapperMobile .buttonWrapperDesktop {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
}

.buttonWrapperDesktop {
  display: none;
}

.trainButton {
  composes: root primary from 'components/design-system/button/styles.module.css';
  border: 1px solid rgba(0, 0, 0, 0) !important;
  border-radius: 0.25rem !important;
  font-size: 1.125rem !important;
  font-weight: 500 !important;
  line-height: 1.75rem !important;
  padding: 0.625rem 1.5rem !important;
  width: 100%;
}

.border {
  width: 80px;
  height: 2px;
  background-image: linear-gradient(to right, #830051, #ff6d42);
}

@media only screen and (min-width: 992px) {
  .container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 3rem;
    width: 100%;
  }

  .leftCol {
    display: flex;
    width: 50%;
    justify-content: flex-end;
    align-items: center;
    padding: 0 2rem;
  }

  .rightCol {
    width: 50%;
    padding: 0;
    margin: 0;
    padding: 0 2rem;
  }

  .buttonWrapperMobile,
  .buttonWrapperDesktop {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .buttonWrapperMobile {
    display: none;
  }

  .buttonWrapperDesktop {
    display: flex;
    flex-direction: row;
    margin: 2rem 0;
  }

  .trainButton {
    font-size: 1.25rem !important;
    line-height: 2rem !important;
    padding: 0.75rem 1.75rem !important;
  }
}
