@value breakpoints: 'styles/breakpoints.module.css';
@value TABLET_WIDTH from breakpoints;

.link {
  composes: unstyled from 'styles/links.module.css';
}

.button {
  composes: baseV2 mediumV2 solidOrange from 'styles/buttons.module.css';
  width: 100%;
}

@media screen and (min-width: TABLET_WIDTH) {
  .button {
    padding: 0.65rem;
    width: inherit;
  }
}

@media screen and (max-width: TABLET_WIDTH) {
  .button {
    font-size: 1.2rem;
    font-weight: 500;
  }
}