@value breakpoints: 'styles/breakpoints.module.css';
@value TABLET_WIDTH, NEW_MOBILE_MAX from breakpoints;

.container {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

.video {
  composes: cover from 'styles/position.module.css';
  object-fit: cover;
  object-position: 75% center;
  width: 100%;
  height: 100%;
}

.overlay {
  composes: cover from 'styles/position.module.css';
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.10) 0%, rgba(255, 0, 0, 0.30) 100%);
}

.overlay2 {
  composes: cover from 'styles/position.module.css';
  top: 25%;
  background: linear-gradient(180deg, rgba(35, 0, 0, 0.00) 0%, rgba(35, 0, 0, 0.8) 64%);
}

@media screen and (min-width: TABLET_WIDTH) {
  .video {
    object-position: center 15%;
  }
}