@value colors: 'styles/colors.module.css';
@value MID_GREY, ORANGE from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value MOBILE_WIDTH_MAX, TABLET_WIDTH from breakpoints;

.carousel {
  display: flex;
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  flex: 1;
  overflow: hidden;
  padding-bottom: 2.5rem;
}

.details :global(.swiper) {
  max-width: 27rem;
  margin-left: 0;
}

.images {
  flex: 1;
  overflow: hidden;
}

.card {
  font-size: 1.5rem;
}

.cardText {
  margin-bottom: 3.125rem;
}

.cardTextMobile {
  display: none;
}

.cardName {
  font-weight: 500;
}

.cardLabel {
}

.cardImages {
  position: relative;
}

.primaryImageWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 14rem;
}

.controls {
  display: flex;
  align-items: center;
}

.swiperButton {
  composes: unstyled from 'styles/buttons.module.css';
  display: block;
}

.swiperButtonNext {
  composes: swiperButton;
  margin-left: 1rem;
}

.swiperButtonPrevious {
  composes: swiperButton;
  margin-right: 1rem;
}

.swiperPagination {
}

.swiperBullet {
  composes: swiperButton;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #afafaf;
  margin: 0 0.25rem 0.25rem;
  transition: background-color 0.2s linear;
}

.swiperBulletActive {
  background-color: ORANGE;
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .carousel {
    flex-direction: column-reverse;
  }

  .details {
    flex-direction: column-reverse;
  }

  .card {
    font-size: 1.15rem;
  }

  .cardText {
    display: none;
  }

  .cardTextMobile {
    display: block;
  }

  .controls {
    margin: 1.25rem auto;
    align-self: flex-end;
  }

  .cardName {
    margin-bottom: 1.25rem;
  }

  .cardLabel {
    margin-bottom: 1.25rem;
  }

  .primaryImageWrapper {
    position: static;
    width: 100%;
  }

  .image {
    width: 100%;
    max-height: 250px;
    object-fit: contain;
  }

  .swiperButtonNext,
  .swiperButtonPrevious {
    display: none;
  }
}
