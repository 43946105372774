@value breakpoints: 'styles/breakpoints.module.css';
@value TABLET_WIDTH from breakpoints;
@value gradients: 'components/dashboard/clients/coach-home/gradients.module.css';
@value redGradient from gradients;

.container {
  padding: 3.75rem 1.5rem;
  width: 100%;
}

.content {
  max-width: 62.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.headers {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 1rem;
  max-width: 20rem;
}

.header {
  composes: redGradient;
  font-size: 0.75rem;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.105rem;
}

.subheader {
  font-size: 1.375rem;
  font-weight: 800;
}

.text {
  font-size: 0.875rem;
  font-weight: 600;
}

.image {
  height: 30rem;
  width: 100%;
}

.imageCover {
  height: 15rem;
  margin: 0 -1.5rem;
  width: calc(100% + 3rem);
}

@media screen and (min-width: TABLET_WIDTH) {
  .container {
    padding: 5rem 1.5rem;
  }
  .headers {
    align-items: flex-start;
    text-align: start;
    max-width: unset;
  }

  .header {
    font-size: 1.125rem;
    letter-spacing: 0.105rem;
  }

  .subheader {
    font-size: 2.25rem;
  }

  .text {
    font-size: 1.25rem;
  }

  .content {
    flex-direction: row;
    align-items: center;
  }

  .contentReverse {
    flex-direction: row-reverse;
  }

  .image {
    width: 22.5rem;
    height: 26rem;
    flex-shrink: 0;
  }


}