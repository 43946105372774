@value colors: 'styles/colors.module.css';
@value BLUE, BACKGROUND_BLUE, SHADES_BLACK, WHITE, NEUTRAL_GRAY_50, NEUTRAL_GRAY_500 from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value NEW_TABLET_PORTRAIT_MAX, MOBILE_WIDTH from breakpoints;

.container {
    display: block;
    min-height: 565px;
    height: fit-content;
    width: 100%;
    position: relative;
    background: WHITE;
}

.content {
    padding: 6.5rem 11rem 4.5rem;
}

.preHeader {
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: SHADES_BLACK;
    font-weight: 400;
    padding-bottom: 0.5rem;
}

.header {
    color: SHADES_BLACK;
    font-size: 2.75rem;
    font-weight: 500;
    line-height: 3.5rem;
    align-self: center;
    gap: 1.188rem
}

.listContainer {
}

.paginationRow {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1.5rem;
}

.pageButton {
    background-color: WHITE;
    border: none;
    border-radius: 50%;
    color: #333;
    cursor: pointer;
    font-size: 1rem;
    height: 40px;
    margin: 0 0.5rem;
    padding: 0;
    width: 40px;
    text-align: center;
    line-height: 36px;
    text-decoration: none;
}

.pageButton:hover {
    background-color: BLUE;
    color: WHITE;
    border: none;
}

.pageButton:disabled {
    background-color: BLUE;
    color: WHITE;
    cursor: default;
    border: none;
}

.chevron {
    margin-right: 1.4rem;
    margin-left: 1.4rem;
}

.chevron:hover {
    color: NEUTRAL_GRAY_500;
}

.upperRow {
    display: flex;
    justify-content: space-between;
    padding: 0 0.25rem 2rem;
}

.upperText {
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.688rem;
    letter-spacing: -0.5px;
}

.list {
    padding-top: 2.438rem;
}

.coachCardContainer {
    position: relative;
    display: block;
    overflow: hidden;
    padding: 0;
    height: 22.313rem;
    margin-bottom: 2rem;
}

.coachCardInformationRow {
    display: flex;
    height: 18.313rem;
}

.coachImage {
    position: relative;
    object-fit: cover;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 18rem;
}

.coachDetails {
    padding-left: 4rem;
    padding-top: 3rem;
}

.coachName {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2rem;
    color: SHADES_BLACK;
}

.cta {
    padding-top: 1rem;
    width: 100%;
}

.coachDetailRow {
    padding-top: 1rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;

    max-width: 42rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
}

.coachSubjectsRow {
    background: NEUTRAL_GRAY_50;
    display: flex;
    height: 4rem;
    width: 100%;
    position: absolute;
    bottom: 0;
    align-items: center;
    padding-left: 1.75rem;
    gap: 0.5rem;
    overflow-x: scroll;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.coachSubjectsRow::-webkit-scrollbar {
    display: none;
}

.viewMoreButton {
    display: flex;
    justify-content: center;
}

@media screen and (max-width: NEW_TABLET_PORTRAIT_MAX) {
    .content {
        padding: 2rem;
    }

    .coachDetails {
        padding-left: 2.5rem;
    }

    .coachDetailRow {
        max-width: 22rem;
    }
}

@media screen and (max-width: MOBILE_WIDTH) {
    .content {
        padding: 1.75rem;
    }

    .header {
        font-weight: 700;
        font-size: 2.5rem;
        line-height: 3rem;
    }

    .list {
        padding-top: 1rem;
    }

    .upperText {
        font-size: 1rem;
        max-width: 8rem;
    }

    .upperRow {
        padding-bottom: 1rem;
    }

    .coachDetails {
        padding: 1.5rem;
    }

    .coachCardInformationRow {
        flex-direction: column;
        height: 36rem;
    }

    .coachCardContainer {
        height: 40rem;
    }

    .coachImage {
        width: 100%;
        height: 100%;
    }

    .coachDetailRow {
        max-width: 20rem;
    }

    .coachButton {
        width: 100%;
    }
}
