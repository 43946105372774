@value colors: 'styles/colors.module.css';
@value PURPLE, WHITE, NEUTRAL_GRAY_50 from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.signUpSection {
  display: flex;
  flex-direction: column;
  /* background-color: NEUTRAL_GRAY_50; */
  width: 100%;
  align-items: center;
  /* margin-bottom: 70px; */
  padding: 5rem 1rem;
}