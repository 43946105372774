@value colors: 'styles/colors.module.css';
@value PURPLE, WHITE, NEUTRAL_GRAY_100, BLUE_GRAYS_015, BLUE_GRAYS_04, BLUE_GRAYS_05 from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label {
  font-size: 1.125rem;
  font-weight: 600;
  margin-top: 0.25rem;
  margin-right: 0.25rem;
}

.options {
  display: flex;
  /* height: 2.75rem; */
  padding: 0.25rem;
  align-items: flex-start;
  border-radius: 2.3125rem;
  border: 0.125rem solid BLUE_GRAYS_015;
}

.option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.0625rem;
  align-self: stretch;
  width: 6rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.selected {
  height: 2.25rem;
  border-radius: 1.25rem;
  background: WHITE;
  box-shadow: 0rem 0.625rem 1.875rem 0rem rgba(0, 0, 0, 0.10);
}

.text {
  font-size: 0.875rem;
  font-weight: 800;
  color: BLUE_GRAYS_05;
}

.selected > .text {
  background: linear-gradient(270deg, #E57054 0%, #85254E 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subtext {
  font-size: 0.625rem;
  font-weight: 800;
  color: BLUE_GRAYS_04;
}

@media screen and (min-width: TABLET_WIDTH) {
  .options {
    margin-top: 0;
  }

  .container {
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
  }
}