@value colors: 'styles/colors.module.css';
@value PURPLE, WHITE, NEUTRAL_GRAY_300 from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 1.5rem;
}

.select {
  width: 100%;
  margin-bottom: 1rem;
}

button.cta {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  padding: 1rem;
  height: 2.5rem;
  width: 100%;
}

button.cta > svg {
  margin-left: 0.5rem;
}

.spin {
  animation: spin 1s linear infinite;
}

.arrowIcon {
  height: 1rem;
  width: 1rem;
}

@media (min-width: TABLET_WIDTH) {
  .container {
    margin-bottom: 2.5rem;
  }

  .select {
    width: 16rem;
    margin-right: 1rem;
    width: 350px;
    margin-bottom: 0;
  }

  button.cta {
    width: 12.25rem;
  }
}

@media screen and (min-width: DESKTOP_MED_WIDTH) {
  .select {
    width: 450px;
  }

  .arrowIcon {
    height: 1.5rem;
    width: 1.5rem;
  }

  button.cta {
    height: 3.5rem;
    padding: 1rem 1.5rem;
    width: 13.5rem;
  }
}

@media screen and (min-width: 1440px) {
  .container {
    margin-bottom: 0;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
