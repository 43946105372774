@value colors: 'styles/colors.module.css';
@value PRIMARY_DEFAULT, NEUTRAL_GRAY_50, NEUTRAL_GRAY_300, NEUTRAL_GRAY_400, NEUTRAL_GRAY_500, ORANGE, ACCENT_GREY, SILVER, BACKGROUND_PURPLE, PURPLE from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value TABLET_WIDTH, NEW_TABLET_LANDSCAPE from breakpoints;

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
}

.header {
  color: PRIMARY_DEFAULT;
  font-weight: 700;
  line-height: 1.25rem;
  letter-spacing: 0.07rem;
  text-transform: uppercase;
  font-size: 1.25rem;
}

.noButtonMargin {
  margin: 0;
}

.editSpecialtiesRow {
  padding-top: 1rem;
  display: flex;
  gap: 0.25rem;
}

.specialties {
  list-style-type: square;
  padding: 1rem;
  padding-bottom: 0;
}

.specialtyContent {
  display: block;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.75rem;
  padding: 0.25rem 0.5rem;
}

.trainButton {
  width: 100%;
}

@media screen and (min-width: NEW_TABLET_LANDSCAPE) {
  .specialtyContent {
    font-size: 1.25rem;
  }
}
