@value colors: 'styles/colors.module.css';
@value SOLID_BRICK_RED, PURPLE, SILVER from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value MOBILE_WIDTH_MAX from breakpoints;

.signUpSection {
  background-color: SOLID_BRICK_RED;
  padding: 4.5rem 3.125rem;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
}

.text {
  color: SILVER;
}

.header {
  composes: text;
  composes: header from 'styles/homepage.module.css';
  line-height: 6rem;
  margin-bottom: 4.0625rem;
}

.main {
  display: flex;
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .signUpSection {
    padding: 3rem 1.5rem;
  }

  .main {
    display: block;
    width: 100%;
  }

  .header {
    font-size: 2.5rem;
    line-height: 3.5rem;
    margin-bottom: 2rem;
  }
}