@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.imageContainer {
  display: flex;
  justify-content: center;
  min-width: 100%;
  min-height: 500px;
  width: auto;
  height: auto;
}

.flexStart {
  justify-content: flex-start;
}

.flexEnd {
  justify-content: flex-end;
}

.content {
  margin-top: 1.5rem;
  font-size: 1.25rem;
}

.p {
  margin-bottom: 1.5rem;
}

.ul {
  list-style-type: disc;
  list-style-position: outside;
  list-style: none;
  margin-left: 1rem;
}

.li {
  list-style-type: disc;
  font-size: 1rem;
  line-height: 1.5rem;
}

.li:not(:last-child) {
  margin-bottom: 1rem;
}

.title {
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: 500;
  text-align: center;

  padding-top: 3rem;
}

@media (min-width: DESKTOP_MED_WIDTH) {
  .hasTitle {
    padding-top: 0;
  }

  .title {
    font-size: 3rem;
    line-height: 4rem;
    margin-bottom: 2.5rem;

    padding-top: 4.5rem;
  }
}