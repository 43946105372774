@value colors: 'styles/colors.module.css';
@value PURPLE, ORANGE, WHITE, SILVER, BLACK from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value MOBILE_WIDTH_MAX, TABLET_WIDTH, MOBILE_WIDTH from breakpoints;

.featureSection {
  padding: 5rem 3.125rem;
  background-color: WHITE;
}

.header {
  color: BLACK;
  font-size: 2.5rem;
  font-weight: 500;
  margin-bottom: 2rem;
  text-align: center;
}

.swiperWrapper {
  position: relative;
  margin: 0 -3.125rem;
}

.feature {
}

.imageWrapper {
  width: 100%;
  height: 17rem;
  border-radius: 0.5rem;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
}

.featureContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  composes: cover from 'styles/position.module.css';
  padding: 1rem 1.25rem;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.35) 30%
  );
}

.featureLabel {
  color: SILVER;
  font-size: 1.5rem;
}

.swiperButton {
  composes: unstyled from 'styles/buttons.module.css';
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 1.2rem;
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}

.swiperButton:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

.nextButton {
  composes: swiperButton;
  right: 1rem;
}

.previousButton {
  composes: swiperButton;
  left: 1rem;
}

.arrowIcon {
  width: 1.5rem;
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .featureSection {
    padding: 3rem 1.5rem;
  }

  .swiperWrapper {
    margin: 0 -1.5rem;
  }
}

@media screen and (max-width: TABLET_WIDTH) {
  .imageWrapper {
    height: 12rem;
  }

  .featureContent {
    padding: 0.5rem 1rem;
  }

  .featureLabel {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: MOBILE_WIDTH) {
  .header {
    font-size: 1.8rem;
  }

  .imageWrapper {
    height: 10rem;
  }
}
