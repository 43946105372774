.container {
  display: flex;
  flex-direction: column-reverse;
}

@media only screen and (min-width: 992px) {
  .container {
    flex-direction: row-reverse;
    margin-top: 3rem;
    align-items: center;
  }

  .leftCol {
    display: flex;
    width: 50%;
    justify-content: flex-end;
    padding: 0 2rem;
  }

  .rightCol {
    display: flex;
    width: 50%;
    justify-content: flex-start;
    padding: 0 2rem;
  }

  .rightColMobile {
    display: none;
  }
}
