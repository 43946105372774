@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.container {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  bottom: 1rem;
  color: black;
  right: 1rem;
  z-index: 1000;
}

.stickyCtaVisible {
  bottom: 6rem;
}

@media screen and (min-width: DESKTOP_WIDTH) {
  .container {
    bottom: 1.5rem;
  }
}
