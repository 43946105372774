@value breakpoints: 'styles/breakpoints.module.css';
@value TABLET_WIDTH from breakpoints;
@value gradients: 'components/dashboard/clients/coach-home/gradients.module.css';
@value greenGradient, redGradient from gradients;


.container {
  width: 100%;
  max-width: 62.5rem;
  margin: 0 auto;
  padding: 3.75rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.75rem;
}

.headers {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-shrink: 1;
}

.header {
  composes: redGradient;
  font-size: 1.75rem;
  font-weight: 800;
  text-align: center;
}

.subheader {
  font-size: 0.875rem;
  font-weight: 800;
  line-height: 1.25rem;
  text-align: center;
}

.image {
  width: calc(100% + 3rem);
  height: 20rem;
  flex-shrink: 0;
}


@media (min-width: TABLET_WIDTH) {
  .headers {
    margin-right: 1rem;
  }
  .header {
    font-size: 3rem;
    line-height: 4.25rem;
  }

  .container {
    flex-direction: row-reverse;
  }

  .image {
    height: 16rem;
    max-width: 26rem;
  }
}
