@value breakpoints: 'styles/breakpoints.module.css';
@value TABLET_WIDTH from breakpoints;

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5rem 1rem;
  width: 100%;
  background: #FFF;
  color: #030017;
}

.content {
  width: 100%;
  max-width: 46.25rem;
}

.header {
  font-size: 1.625rem;
  font-weight: 800;
  text-align: center;
  line-height: 2.125rem;
  margin-bottom: 5rem;
}

.faqs {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.25rem;
}

@media screen and (min-width: TABLET_WIDTH) {
  .header {
    font-size: 2.5rem;
  }
}