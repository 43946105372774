@value colors: 'styles/colors.module.css';
@value PURPLE, ORANGE from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, MOBILE_WIDTH_MAX, TABLET_WIDTH from breakpoints;

.kickOff {
  margin-bottom: 5rem !important;
}

.alignCenter {
  align-items: center;
}

.images {
  composes: sectionImages from '../styles.module.css';
}

.image {
  composes: sectionImage from '../styles.module.css';
  height: 100%;
}

.promoMain {
  font-size: 1.5rem;
  color: ORANGE;
  margin-top: 3rem;
}

.promoSub {
  font-size: 1.125rem;
  margin-top: 0.5rem;
}

.promoSub em {
  font-weight: 500;
}

.detail {
  display: flex;
  align-items: center;
  margin-top: 3.5rem;
}

.detailText {
  font-size: 1.125rem;
  font-weight: 500;
  color: #732eb0;
}

.bikeEmoji {
  width: 2.5rem;
  flex-shrink: 0;
  margin-right: 0.8rem;
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .kickOff {
    height: auto;
    padding: 0;
    margin-bottom: 3rem !important;
  }

  .promoMain {
    font-size: 1.25rem;
    margin-top: 2rem;
  }

  .detail {
    margin-top: 1.5rem;
  }
}
