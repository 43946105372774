@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.stars {
  display: flex;
  /* margin-bottom: 1rem; */
  align-items: center;
}

.star {
  width: 14px;
  height: 14px;
  margin-bottom: 2px;
}

.rating {
  margin-left: 0.5rem;
}

@media screen and (min-width: DESKTOP_WIDTH) {
  .star {
    width: 16px;
    height: 16px;
  }

  .rating {
    font-size: 1rem;
  }
}
