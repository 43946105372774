@value colors: 'styles/colors.module.css';
@value PURPLE, WHITE, NEUTRAL_GRAY_100 from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.container {
  margin: 2.5rem 1rem;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 1rem;
  background-color: NEUTRAL_GRAY_100;
  border-radius: 0.5rem;
}

.logoContainer {
  display: flex;
}

.logo {
  height: 1rem;
}

.title {
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 500;
  margin-top: 0.5rem;
}

@media screen and (min-width: TABLET_WIDTH) {
  .title {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}

@media screen and (min-width: DESKTOP_MED_WIDTH) {
  .title {
    font-size: 4.5rem;
    line-height: 5.5rem;
  }

  .container {
    margin: 6.75rem;
  }
}
