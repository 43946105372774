@value colors: 'styles/colors.module.css';
@value NEUTRAL_GRAY_50, NEUTRAL_GRAY_500, SHADES_WHITE, PRIMARY_DEFAULT, MID_GREY, ORANGE from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value MOBILE_WIDTH_MAX, TABLET_WIDTH from breakpoints;

.testimonialsSection {
  padding: 2.5rem 3.125rem;
  background-color: SHADES_WHITE;
}

.testimonialsSectionContent {
  display: flex;
}

.header {
  composes: header from 'styles/homepage.module.css';
  line-height: 6rem;
  margin-bottom: 2.5rem;
}

.mobileHeader {
  composes: header from 'styles/homepage.module.css';
  display: none;
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  flex: 1;
  width: 50%;
  overflow: hidden;
  padding-bottom: 4.5rem;
}

.details :global(.swiper) {
  max-width: 600px;
  margin-left: 0;
}

.images {
  flex: 1;
  width: 50%;
  overflow: hidden;
  max-height: 32rem;
}

.testimonial {
  font-size: 1.5rem;
  width: 100% !important;
}

.testimonialText {
  margin-bottom: 3.125rem;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.testimonialTextMobile {
  display: none;
}

.testimonialName {
  font-weight: 500;
}

.testimonialLabel {
}

.testimonialImages {
  position: relative;
  height: 100%;
}

.swiperContainer {
  height: 100%;
}

.afterImageWrapper {
  height: 100%;
}

.image {
  margin: auto;
}

.video {
  position: static;
  transform: none;
  height: 18rem;
  width: 100%;
  z-index: auto;
}

.beforeImageWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 14rem;
  height: 100%;
}

.images :global(.swiper-pagination-bullet-active.swiper-pagination-bullet) {
  display: none;
}

.images :global(.swiper) {
  height: 100%;
}

.images :global(.swiper-pagination-bullet) {
  display: none;
}

.details :global(.swiper) {
  width: 100%;
  padding-bottom: 40px;
}

.details :global(.swiper-pagination-bullet-active.swiper-pagination-bullet) {
  background-color: PRIMARY_DEFAULT;
  opacity: 1;
}

.details :global(.swiper-pagination-bullet) {
  background-color: #afafaf;
  opacity: 1;
  width: 15px;
  height: 15px;
}

.swiperButton {
  composes: unstyled from 'styles/buttons.module.css';
  display: block;
}

.details :global(.next) {
  position: absolute;
  background-color: transparent;
  padding: 0;
  appearance: none;
  background: none;
  outline: none;
  border: none;
  user-select: none;
  bottom: 12px;
  right: 0;
  z-index: 10;
}

.details :global(.prev) {
  position: absolute;
  background-color: transparent;
  padding: 0;
  appearance: none;
  background: none;
  outline: none;
  border: none;
  user-select: none;
  bottom: 12px;
  z-index: 10;
}

.getStartedWrapper {
  display: flex;
  align-items: center;
}

.getStartedPrompt {
  /* color: ORANGE; */
  color: NEUTRAL_GRAY_500;
  font-size: 1.5rem;
  margin-right: 1.5rem;
}

.getStartedButton {
  composes: root primary from 'components/design-system/button/styles.module.css';
  border: 1px solid rgba(0, 0, 0, 0) !important;
  border-radius: 0.25rem !important;
  font-size: 1.125rem !important;
  font-weight: 500 !important;
  line-height: 1.75rem !important;
  padding: 0.625rem 1.5rem !important;
  width: 100%;
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .testimonialsSection {
    padding: 1.5rem;
  }

  .testimonialsSectionContent {
    flex-direction: column-reverse;
    max-height: unset;
  }

  .images :global(.swiper) {
    width: 100%;
    padding-bottom: 40px;
  }

  .images :global(.swiper-pagination-bullet-active.swiper-pagination-bullet) {
    display: inline-block;
    background-color: PRIMARY_DEFAULT;
    opacity: 1;
  }

  .images :global(.swiper-pagination-bullet) {
    display: inline-block;
    background-color: #afafaf;
    opacity: 1;
    width: 15px;
    height: 15px;
  }

  .details :global(.swiper-pagination-bullet-active.swiper-pagination-bullet) {
    display: none;
  }

  .details :global(.swiper-pagination-bullet) {
    display: none;
  }

  .header {
    display: none;
  }

  .images {
    width: 100%;
  }

  .details {
    flex-direction: column-reverse;
    width: 100%;
  }

  .details :global(.swiper) {
    max-width: 100%;
  }

  .mobileHeader {
    display: block;
    line-height: 4.4rem;
    margin-bottom: 1.5rem;
  }

  .testimonial {
    font-size: 1.15rem;
  }

  .testimonialText {
    display: none;
  }

  .testimonialTextMobile {
    display: block;
  }

  .controls {
    margin: 1.25rem auto;
    align-self: flex-end;
  }

  .testimonialName {
    margin-bottom: 1.25rem;
  }

  .testimonialLabel {
    margin-bottom: 1.25rem;
  }

  .swiperButtonNext,
  .swiperButtonPrevious {
    display: none;
  }

  .image {
    max-height: 300px !important;
  }
}
