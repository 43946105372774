@value breakpoints: 'styles/breakpoints.module.css';
@value MOBILE_WIDTH_MAX from breakpoints;

.container {
  display: flex;
  justify-content: center;
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem 1rem 1rem;
  gap: 1rem;
}

.container > span {
  display: flex;
  align-items: center;
}

.backButton {
  padding-top: 1rem;
  padding-left: 0;
  color: black;
}

.backContainer {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .backContainer {
    padding-left: 1rem;
  }
}
