@value colors: 'styles/colors.module.css';
@value PURPLE, SILVER from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value MOBILE_WIDTH_MAX from breakpoints;

.signUpSection {
  background-color: PURPLE;
  padding: 1rem 3.125rem 2.5rem 3.125rem;
}

.text {
  color: SILVER;
}

.header {
  composes: text;
  composes: header from 'styles/homepage.module.css';
  line-height: 6rem;
}

.main {
  display: flex;
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .signUpSection {
    padding: 3rem 1.5rem;
  }

  .main {
    display: block;
    width: 100%;
  }

  .header {
    line-height: 3.5rem;
    margin-bottom: 2rem;
  }
}
