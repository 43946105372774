@value colors: 'styles/colors.module.css';
@value NEUTRAL_GRAY_600, NEUTRAL_GRAY_900, SILVER, PURPLE, WHITE from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value MOBILE_WIDTH_MAX, TABLET_WIDTH from breakpoints;

.section {
  flex: 1;
  padding: 4.0625rem 3.125rem;
}

.header {
  composes: header from 'styles/homepage.module.css';
  color: NEUTRAL_GRAY_900;
  margin-bottom: 0.5rem;
}

.subHeader {
  font-size: 1.65rem;
  color: NEUTRAL_GRAY_600;
  margin-bottom: 2.5rem;
}

.content {
  display: flex;
  align-items: center;
}

.plans {
  flex: 1;
  margin-right: 3.125rem;
  min-width: 500px;
  max-width: 800px;
}

.imageWrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  flex: 1;
  max-height: 500px;
  max-width: 100%;
  object-fit: contain;
}

.discount {
  color: WHITE !important;
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .section {
    padding: 3rem 1.5rem;
  }

  .imageWrapper {
    display: none;
  }

  .plans {
    margin-right: 0;
    min-width: auto;
    width: 100%;
  }
}
