@value breakpoints: 'styles/breakpoints.module.css';
@value MOBILE_WIDTH_MAX from breakpoints;

.header {
  font-size: 3.5rem;
  font-weight: 500;
}

.subHeader {
  font-size: 0.9375rem;
  font-weight: 500;
  text-transform: uppercase;
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .header {
    font-size: 3.2rem;
  }
}
