@value breakpoints: 'styles/breakpoints.module.css';
@value MOBILE_WIDTH_MAX from breakpoints;

.container {
  max-width: 1300px;
  margin: 0 auto;
}

.billingPeriods {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.products {
  display: flex;
  margin: 0 -1rem;
  flex-wrap: wrap;
}

.product {
  flex: 1 1 33%;
  padding: 0 1rem 2rem 1rem;
  max-width: 33%;
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .products {
    display: block;
    max-width: 500px;
    margin: 0 auto;
  }

  .product {
    margin-bottom: 2rem;
    padding: 0;
    flex: 1;
    max-width: 100%;
  }
}