@value colors: 'styles/colors.module.css';
@value SILVER, WHITE, ORANGE, GREEN, CHARCOAL, ACCENT_GREY, PURPLE from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value MOBILE_WIDTH_MAX from breakpoints;

.text {
  color: SILVER;
}

.formWrapper {
  width: 50%;
}

.formLabel {
  composes: text;
  font-size: 1.6rem;
  margin-bottom: 2rem;
  max-width: 40.5rem;
}

.form {
  padding: 1.25rem;
  border-radius: 0.75rem;
  background-color: WHITE;
  max-width: 800px;
}

.goalsLabel {
  color: #777777;
  font-size: 1.25rem;
  margin-bottom: 1.5625rem;
  display: block;
}

.input {
  composes: baseV2 from 'styles/inputs.module.css';
  display: block;
  width: 100%;
  border-color: ORANGE;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  width: 80%;
}

.select {
  display: flex;
  flex-wrap: wrap;
}

.selectOption {
  width: 50%;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  margin-top: 1.625rem;
  background: none;
  border: none;
  cursor: pointer;
}

.checkboxContainer label {
  font-size: 1.125rem;
  margin-left: 1.25rem;
}

.submitButton {
  composes: baseV2 from 'styles/buttons.module.css';
  composes: mediumV2 from 'styles/buttons.module.css';
  composes: solidOrange from 'styles/buttons.module.css';
  padding: 0.65rem;
  width: 13.5rem;
  margin-top: 1.625rem;
}

.continueButton {
  composes: submitButton;
}

.signOutLink {
  text-decoration: none;
}

.signOutButton {
  composes: baseV2 from 'styles/buttons.module.css';
  composes: mediumV2 from 'styles/buttons.module.css';
  composes: hollowPurple from 'styles/buttons.module.css';
  display: block;
  padding: 0.65rem;
  width: 13.5rem;
  margin-top: 2.5rem;
}

.spinner {
  color: WHITE !important;
  margin: -6px 0;
  font-size: 1.5rem !important;
}

.welcomeBack {
  font-size: 1.5rem;
  color: CHARCOAL;
}

.error {
  color: ORANGE;
  margin-top: 0.5rem;
}

.message {
  color: GREEN;
  font-size: 1.1rem;
  margin-top: 0.5rem;
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .formWrapper {
    width: 100%;
  }

  .select {
    display: block;
  }

  .selectOption {
    width: 100%;
  }

  .selectOption span {
    font-size: 1.28rem;
  }

  .input {
    font-size: 1.5rem;
    width: 100%;
    max-width: 450px;
  }

  .submitButton,
  .signOutButton {
    width: 100%;
    font-size: 1.5rem;
  }
}